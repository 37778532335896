import { CapacitorHttp } from '@capacitor/core';

export default () => {
    const getIdentificationData = async (apiUrl, token, userToken) => {
        const options = {
            url: `${apiUrl}/api/v1/customer/identifications/edit`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return [err, response.status];
        }
    };

    const removeFileUpload = async (apiUrl, token, userToken, fileId, fileType) => {
        const options = {
            url: `${apiUrl}/api/v1/customer/identifications/${fileId}/destroy`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                identity: {
                    file_type: fileType,
                    file_id: fileId
                }
            }
        };


        try {
            const response = await CapacitorHttp.patch(options);
            if (response.status === 200) {
                return {
                    success: true,
                    message: 'The application was successfully withdrawn'
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return {
                success: false,
                error: err
            }
        }
    };

    const submitIdDocs = async (apiUrl, token, userToken, data) => {

        let formData = new FormData();
        data.forEach((item) => {
            if (item.files && item.files.length > 0) {
                item.files.forEach((file, i) => {
                    console.log('file: ', file);
                    formData.append([item.type.label, i.toString()], file, file.name);
                });
            }
            formData.append(item.type.label + '_type', item.type.value);
        });

        const options = {
            url: `${apiUrl}/api/v1/customer/identifications`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
                Token: userToken
            },
            data: formData
        };


        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `Documents were successfully updated`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return {
                success: false,
                error: err
            }
        }
    };

    return {
        getIdentificationData,
        removeFileUpload,
        submitIdDocs
    }
}