<template>
  <ion-list :id="id" class="w-full bg-transparent py-2">
    <div :class="['relative flex flex-row']" >
        <div v-show="!hasImg" class="pl-2">
            <ProfileInitialsIcon :initials="activeCompany ? activeCompany.initials : companies ? companies[0].initials : ''" :fillColor="activeCompany ? activeCompany.color : companies ? companies[0].color : ''" />
        </div>
        <div v-show="hasImg" class="pl-2">
            <BankGenIcon />
        </div>
        <div class="flex flex-col justify-center items-start flex-grow relative gap-px">
        <div :class="['w-full flex flex-col', disabledModal ? 'pointer-events-none' : 'cursor-pointer']" @click="openModal">
            <p class="text-[18px] font-bold text-left text-gray-900">
              {{ activeCompany ? activeCompany.trading_name  : companies ? companies[0].trading_name : '' }}
            </p>
            <p class="text-[14px] text-left text-gray-900">{{ activeCompany ? activeCompany.legal_name : companies ? companies[0].legal_name : ''}}</p>
        </div>
        <a 
          v-if="analyticsBeta"
          @click="redirectToPath('/home/profile')"
          class="block md:hidden bottom-0 underline left-[25%] font-medium text-left text-primary-900 cursor-pointer">
          View profile
        </a>
        <a 
          v-if="analyticsBeta"
          @click="router.push('/home/profile')"
          class="hidden md:block bottom-0 underline left-[25%] font-medium text-left text-primary-900 cursor-pointer">
          View profile
        </a>
        <div
          v-show="cashflowTwoWeeksEnabled()" 
          class="w-full flex flex-row gap-2">
          <p class="text-[12px] pt-1">New features available</p>
          <div 
            class="flex justify-start items-start gap-2 px-1.5 py-0.5 rounded-md bg-blue-50 border border-primary-900">
            <p class="text-[12px] font-semibold text-left text-primary-900">New</p>
          </div>
        </div>
        </div>
    </div>
  </ion-list>
</template>

<script>
import { IonList, IonItem, IonSelect, IonSelectOption, modalController, menuController } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import ProfileIcon from '@/components/icons/ProfileIcon';
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import ProfileInitialsIcon from '@/components/icons/ProfileInitialsIcon';
import BankIcon from '@/components/icons/BankIcon';
import BankGenIcon from '@/components/icons/BankGenIcon';
import SelectModal from "@/components/ui/modals/SelectModal";
import useAuthApi from "@/apis/useAuthApi.js";
import { useNavStore } from "@/stores/nav";

export default defineComponent({
  name: "GridSelect",
  components: { IonList, 
  //IonItem, IonSelect, IonSelectOption, 
  ProfileInitialsIcon, 
  BankGenIcon 
  },
  props: {
    companies: {
      type: Array,
      default: () => [ 
        { 
          compact_full_address_line: "4 GLENVILLE TERRACE MAIN STREET DUNDRUM DUBLIN 14",
          color: "#0CCBCF",
          initials: "XL",
          company_type: "limited_company",
          cro_number: "000000",
          id: 1158,
          lead_type: "borrower",
          legal_name: "SV Retail Investments Ltd",
          trading_name: "XL Gorey Co."
        },
        { 
          compact_full_address_line: "4 GLENVILLE TERRACE MAIN STREET DUNDRUM DUBLIN 14",
          color: "#ff00ff",
          initials: "GB",
          company_type: "limited_company",
          cro_number: "000000",
          id: 1156,
          lead_type: "borrower",
          legal_name: "Bay Sales S.R.L.",
          trading_name: "Gorey Bay Inc."
        },
        { 
          compact_full_address_line: "4 GLENVILLE TERRACE MAIN STREET DUNDRUM DUBLIN 14",
          color: "#00ffff",
          initials: "AG",
          company_type: "limited_company",
          cro_number: "000000",
          id: 1162,
          lead_type: "borrower",
          legal_name: "Michael Douglas and Partners Ltd.",
          trading_name: "Association Gorey Ltd."
        }
      ], 
    },
    activeCompany: {
      type: Object,
    },
    items: {
      type: Array,
      default: () => [
      {label: 'Amazing Pizza Co.'},
      {label: 'Bobs Burgers Inc.'},
      {label: 'Jimmy Pestos S.L.'},
    ]
    },
    label: {
        type: String,
        default: () => "Label"
    },
    icon: {
        type: String,
        default: () => "BellNavIcon"
    },
    disabledModal: {
      type: Boolean,
      default: () => false
    },
    id: {
        type: String,
        default: () => "grid-select"
    },
    hasImg: {
      type: Boolean,
      default: () => false
    },
    isCompany: {
      type: Boolean,
      default: () => false
    },
    isAccount: {
      type: Boolean,
      default: () => false
    },
  },
  setup(props) {
    const router = useRouter();
    const userStore = useUserStore();
    const { getCurrentUser } = useAuthApi();
    const navStore = useNavStore();
    const selectedVal = ref(props.items[0].label);
    const analyticsBeta = ref(false);
    const updateSelectedValue = (selected) => {
        selectedVal.value = selected.detail.value;
    }

    const openModal = async () => {
      const modal = await modalController.create({
        component: SelectModal, 
        id:"SelectModal",
        componentProps: {
          isCompany: props.isCompany,
          isAccount: props.isAccount,
          companies: props.companies,
          activeCompanyObj: props.activeCompany ? props.activeCompany : props.companies[0]
        },
      });
      return modal.present();
    };

        const cashflowTwoWeeksEnabled = () => {
          if(userStore.userObj.subscription?.subscribed_since) {
            let dateEnabled;
            if(userStore.userObj.subscription?.subscribed_since) dateEnabled = new Date(userStore.userObj.subscription?.subscribed_since);
            let dateToday = new Date();
            let TimeDiff = dateToday.getTime() - dateEnabled.getTime();
            let DaysDiff = TimeDiff / (1000 * 3600 * 24);
            if(15 - DaysDiff > 0) return true;
            else return false;
          } else {
            return false;
          }
        }

    const redirectToPath = async (path) => {
      await menuController.enable(false, 'main-side-menu'); 
      //router.push(path)
      window.location.href=path
        /*return new Promise((resolve, reject) => {
          resolve(router.push(path));
        });*/
        //await getCurrentUser(navStore.apiUrl, navStore.authToken, userStore.userObj.token, userStore.userObj.id);
        //router.go();       
    }   

    onMounted(async () => {
      if(userStore.userObj && userStore.userObj.id) {
        analyticsBeta.value = userStore.userObj.analyticsBeta;
      }
    })

    return { analyticsBeta, selectedVal, updateSelectedValue, openModal, router, redirectToPath, cashflowTwoWeeksEnabled };
  },
});
</script>

<style>
    .action-sheet-group {
        width: 100%;
        position: absolute;
        top: 30%;
    }
    .action-sheet-group-cancel {
        display: none; 
        position: absolute;
        top: 25%;
    }
    .action-sheet-group-cancel.sc-ion-action-sheet-md {
        padding-top: .5rem !important;
    }
    ion-select::part(icon) {
        display: none !important;
    }
    .action-sheet-group.sc-ion-action-sheet-md {
        border-radius: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .action-sheet-button.sc-ion-action-sheet-md {
        padding: 2rem;
    }
    .action-sheet-group.sc-ion-action-sheet-md:first-child {
      padding-top: 1rem;
    }
    .action-sheet-button-inner::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='100' cy='50' r='40' stroke='black' stroke-width='2' fill='red'/%3E%3Cpolyline points='20,20 40,25 60,40 80,120 120,140 200,180' style='fill:none;stroke:black;stroke-width:3'/%3E%3C/svg%3E ");;
    }
</style>
