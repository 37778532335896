<template>
    <div :class="[disabledDivClass, wrapClass]">
        <p :class="label ? disabled ? labelDisabledClass : labelClass : 'hidden'">{{ label }}</p>
        <span class="w-full relative block">
            <span v-show="iconType === 'left' || iconType === 'double'" :class="iconLeftClass">
                <component :is="inputIcon" :fillColor="disabled ? disabledIconColor : iconColor"
                    :iconClass="iconClass" />
            </span>
            <textarea v-if="textarea" :type="inputType" :value="modelValue" :min="minNum && minNum"
                @change="$emit('update:modelValue', $event.target.value)" :id="id" :placeholder="placeholderText"
                :class="[disabled ? disabledClass : baseClass, spacingClass, danger && !disabled ? borderDangerClass : borderClass, customClass]"
                :disabled="disabled" :required="required" />
            <input v-else :type="inputType" :value="modelValue" :min="minNum && minNum"
                @change="$emit('update:modelValue', $event.target.value)" :id="id" :placeholder="placeholderText"
                :class="[disabled ? disabledClass : baseClass, spacingClass, danger && !disabled ? borderDangerClass : borderClass, customClass]"
                :disabled="disabled" :required="required" />
            <span :class="note ? noteClass : 'hidden'">
                <p :class="noteTextClass">{{ note }}</p>
            </span>
            <span class="cursor-pointer" @click="togglePasswordShow"
                v-show="iconType === 'right' || iconType === 'double'" :class="iconRightClass">
                <component :is="inputIcon" :fillColor="disabled ? disabledIconColor : iconColor"
                    :iconClass="iconClass" />
            </span>
        </span>
        <p :class="footerText ? disabled ? footerDisabledClass : danger ? footerDangerClass : footerClass : 'hidden'">{{
            footerText }}</p>
    </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import GridBtnIcon from '@/components/icons/GridBtnIcon'

export default {
    components: {
        GridBtnIcon
    },
    props: {
        label: {
            type: String
        },
        note: {
            type: String
        },
        required: {
            type: Boolean,
            default: () => false
        },
        footerText: {
            type: String
        },
        baseClass: {
            type: String,
            default: () => "w-full flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2.5 py-3 rounded-lg text-neutral-900 placeholder-secondary-300 bg-white"
        },
        labelClass: {
            type: String,
            default: () => "self-stretch flex-grow-0 flex-shrink-0 font-semibold text-left text-gray-500 pb-1"
        },
        noteClass: {
            type: String,
            default: () => "absolute top-3.5 right-13"
        },
        noteTextClass: {
            type: String,
            default: () => "flex-grow-0 flex-shrink-0 text-sm text-right text-gray-500"
        },
        footerClass: {
            type: String,
            default: () => "self-stretch flex-grow-0 flex-shrink-0 text-smaller font-medium text-left text-gray-500 pt-1"
        },
        customClass: {
            type: String,
            default: () => ""
        },
        borderClass: {
            type: String,
            default: () => "border border-neutral-300 focus:outline-none focus:border-primary-900 focus:ring-1 focus:ring-primary-900 focus-visible:border focus-visible:border-primary-900"
        },
        borderDangerClass: {
            type: String,
            default: () => "border border-danger-500 focus:outline-none focus:border-danger-500 focus:ring-1 focus:ring-danger-500 focus-visible:border focus-visible:danger-500"
        },
        variant: {
            type: String,
            default: () => "primary"
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        danger: {
            type: Boolean,
            default: () => false
        },
        disabledClass: {
            type: String,
            default: () => "w-full flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2.5 py-3 rounded-lg text-secondary-100 placeholder-secondary-200 bg-neutral-100 border border-neutral-100 focus:outline-none focus:border-primary-900 focus:ring-1 focus:ring-primary-900 focus-visible:border focus-visible:border-primary-90"
        },
        labelDisabledClass: {
            type: String,
            default: () => "self-stretch flex-grow-0 flex-shrink-0 font-semibold text-left text-neutral-200 pb-1"
        },
        footerDisabledClass: {
            type: String,
            default: () => "self-stretch flex-grow-0 flex-shrink-0 text-smaller font-medium text-left text-neutral-300 pt-1"
        },
        footerDangerClass: {
            type: String,
            default: () => "self-stretch flex-grow-0 flex-shrink-0 text-smaller font-medium text-left text-danger-500 pt-1"
        },
        iconLeftClass: {
            type: String,
            default: () => "absolute top-3 left-5"
        },
        iconRightClass: {
            type: String,
            default: () => "absolute top-3 right-5"
        },
        placeholderText: {
            type: String,
            default: () => ""
        },
        loading: {
            type: Boolean,
            default: () => true
        },
        type: {
            type: String,
            default: () => "text"
        },
        icon: {
            type: String,
            default: () => "GridBtnIcon"
        },
        iconType: {
            type: String,
            default: () => "off"
        },
        iconColor: {
            type: String,
            default: () => "#6B7280"
        },
        disabledIconColor: {
            type: String,
            default: () => "#D1D5DB"
        },
        iconClass: {
            type: String,
            default: () => "flex-grow-0 flex-shrink-0 w-6 h-6 relative"
        },
        wrapClass: {
            type: String,
            default: () => "self-stretch w-full"
        },
        id: {
            type: String
        },
        modelValue: {
            type: String, Array, Number
        },
        minNum: {
            type: Number
        },
        textarea: {
            type: Boolean,
            default: () => false
        }
    },
    setup(props) {
        var spacingClass = '';
        const inputType = ref('text')

        switch (props.type) {
            case 'text':
                inputType.value = 'text';
                break
            case 'password':
                inputType.value = 'password';
                break
            case 'date':
                inputType.value = 'date';
                break
            case 'number':
                inputType.value = 'number';
                break
            default:
                inputType.value = 'text';
                break
        }

        switch (props.iconType) {
            case 'left':
                spacingClass = props.note ? 'pl-13 pr-32' : 'pl-13 pr-5'
                break
            case 'right':
                spacingClass = props.note ? 'pl-5 pr-32' : 'pl-5 pr-13'
                break
            case 'double':
                spacingClass = props.note ? 'pl-13 pr-32' : 'px-13'
                break
            case 'off':
                spacingClass = props.note ? 'pl-5 pr-32' : 'px-5'
                break
            default:
                spacingClass = props.note ? 'pl-5 pr-32' : 'px-5'
                break
        }

        const disabledDivClass = props.disabled ? 'cursor-not-allowed' : '';

        const inputIcon = computed(() => defineAsyncComponent(() => import(`@/components/icons/${props.icon}.vue`)))

        const togglePasswordShow = () => {
            inputType.value === 'text' ? inputType.value = 'password' : inputType.value = 'text';
        };

        return { spacingClass, disabledDivClass, inputIcon, togglePasswordShow, inputType };
    },
};
</script>

<style></style>