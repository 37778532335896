<template>
    <notifications
      v-show="activeBanner"
      classes=""
      width="100%"
      :duration="-1" >
        <template #body="props">
          <TopBannerNotification
            v-show="activeBanner && props.item.data.kind === 'banner'"
            :id="props.item.id"
            :title="props.item.title"
            :text="props.item.text"
            :date="props.item.data.date"
            :link="props.item.data.link"
            :closeNotification="closeNotification" />
        </template>
    </notifications>
    <notifications
      v-show="activeNotification"
      classes=""
      width="100%"
      :duration="-1" >
        <template #body="props">
            <web-push-notification
            v-show="activeNotification  && props.item.data.kind === 'notification'"
            :id="props.item.id"
            :title="props.item.title"
            :text="props.item.text"
            :date="props.item.data.date"
            :sentiment="props.item.data.sentiment"
            :link="props.item.data.link"
            :closeNotification="closeNotification"
            customClass="flex flex-row mr-4 mt-4 !shadow"
            gradientClass="!absolute left-0">
          </web-push-notification>
        </template>
    </notifications>
       <notifications
       v-show="activeFormNotification && !activeNotification"
       :duration="-1"
       :position="position">
      <template #body="props">
        <div :class="formNotificationClass">
          <p :class="formTitleNotificationClass">
            <!-- Submission failed: {{ props.item.title }} -->
            {{ props.item.title }}
          </p>
          <p :class="formTextNotificationClass">
            {{ props.item && props.item.text }}
          </p>
          <XIcon data-value="[close-btn]" @click="closeNotification(props.item.id)" class="w-5 stroke-[2px] text-white cursor-pointer"/>
        </div>
      </template>
    </notifications>

</template>

<script>
  import { Capacitor } from '@capacitor/core';
  import { PushNotifications } from '@capacitor/push-notifications';
  import { defineComponent, onMounted, ref, inject, pushScopeId } from "vue";
  import { modalController, getPlatforms, alertController } from "@ionic/vue";
  import { notify } from "@kyvg/vue3-notification";
  import TopBannerNotification from "@/components/notifications/TopBannerNotification";
  import { useNavStore } from "@/stores/nav";
  import { useUserStore } from "@/stores/user";
  import { useNotificationStore } from "@/stores/notification";
  import { useRouter, useRoute } from "vue-router";
  import useNotificationsApi from "@/apis/useNotificationsApi.js";
  import firebase from "@/service/firebase";
  import { getMessaging, onMessage, getToken } from "firebase/messaging";
  import NotificationModal3 from "@/components/ui/modals/NotificationModal3";
  import WebPushNotification from "@/components/notifications/WebPushNotification";
  import useAuthApi from "@/apis/useAuthApi.js";
  import { XIcon } from '@heroicons/vue/outline';

  export default defineComponent({
    name: "GridNotifications",
    components: {
      TopBannerNotification,
      WebPushNotification,
      XIcon
    },
    setup() {
      const route = useRoute();
      const { getCurrentUser, logoutUser } = useAuthApi();
      const isNativeApp = ref(Capacitor.isNativePlatform());
      const source = ref(getPlatforms()[0] + '-' + Capacitor.getPlatform());
      const emitter = inject('emitter');
      const activeBanner = ref(false);
      const activeNotification = ref(false);
      const activeFormNotification = ref(true);
      const notificationStore = useNotificationStore();
      const userStore = useUserStore();
      const navStore = useNavStore();
      const { getAllNotifications, updateFbTokenOnLogin, getLatestBannerNotification, markNotificationAsRead } = useNotificationsApi();
      const duration = ref(5000);
      const position = ref("top right");
      const formNotificationClass = ref('bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative !z-[25000]');
      const formTitleNotificationClass = ref('font-semibold text-neutral-700 text-[14px] p-1');
      const formTextNotificationClass = ref('text-neutral-700 p-1');
      const formCloseNotificationClass = ref('');

      const plaidReadyFlag = ref(false);
      const router = useRouter();

      const closeNotification = (id) => {
        notify.close(id);
      }

      const notificationHandler = async (notification) => {
        if (notification.type === 'alert') {
          formNotificationClass.value = 'flex justify-between items-center relative gap-2.5 px-5 py-3.5 rounded-lg bg-red-500 relative !z-[25000]';
        }
        if (notification.type === 'success') {
          formNotificationClass.value = 'bg-gray-50 border-t-4 border-teal-500 rounded-b text-gray-700 px-4 py-3 shadow-inner';
        }
        if (notification.type === 'submitted') {
          formNotificationClass.value = 'w-full flex flex flex-row bg-primary-900 rounded-xl text-white px-4 py-3 shadow-inner';
          formTitleNotificationClass.value = 'font-semibold text-white text-[14px] p-1';
          formTextNotificationClass.value = 'w-[90%] text-white p-1';
          formCloseNotificationClass.value='flex flex-col items-start text-white p-1 w-[10%]'
          position.value = 'top center';
        }
        if (notification.type === 'warning') {
          formNotificationClass.value = 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4';
        }
        /*if (notification.data) {
          notify({
            id: notification.id,
            title: notification.data.title,
            text: notification.data.text,
          });
        }*/
      }

      emitter.on('formNotification', (notification) => {
        notificationHandler(notification);
      });

      emitter.on('generateFBTokens', () => {
        generateFirebaseTokens(true);
      });

      const addListeners = async (isUserLoggedIn) => {
        await PushNotifications.addListener('registration', token => {
          console.info('Registration token: ', token.value);
            if (token.value && isUserLoggedIn && !userStore.userObj.isMasqueraded && process.env.NODE_ENV === 'production') {
              userStore.updateFbToken(token.value, source.value).then((fbToken) => {
                if (fbToken) {
                  updateFbTokenOnLogin(navStore.apiUrl, navStore.authToken, userStore.userObj.token, token.value, source.value).then((res) => {
                    //console.log('Upd FB on app server: ', res);
                  });
                }
              });
            }
        });

        await PushNotifications.addListener('registrationError', err => {
          console.error('Registration error: ', err.error);
        });

        await PushNotifications.addListener('pushNotificationReceived', notification => {
          //console.log('Push notification received: ', notification);
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          //console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });
      }

      const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!');
        }

        await PushNotifications.register();
      }

      const getDeliveredNotifications = async () => {
        const notificationList = await PushNotifications.getDeliveredNotifications();
        //console.log('delivered notifications', notificationList);
      }

      const registerAppPush = (isUserLoggedIn) => {
        //console.log('********* register App Push');
        addListeners(isUserLoggedIn);
        registerNotifications();
        getDeliveredNotifications();

      }

      const presentPlaidAlert = async () => {
        const alert = await alertController.create({
          header: 'Pending data',
          subHeader: 'Your sheet is not available yet',
          message: "Your personalized results aren't quite ready yet, but we're working on getting them together",
        });

        await alert.present();
      };

      const generateFirebaseTokens = async (isUserLoggedIn) => {
        if (isUserLoggedIn) {
          getLatestBannerNotification(navStore.apiUrl, navStore.authToken, userStore.userObj.token).then((banner) => {
            if (banner && banner.active) {
              notificationStore.updateCurrentBanner(banner.data).then((bannerData) => {
                activeBanner.value = true;
                notify(bannerData);
                emitter.emit('refreshNotifications', 'banner');
              });
            } else {
              activeBanner.value = false;
            }
          })
          /* Get Current Banner */


          if (!isNativeApp.value) {
            //console.log('INSIDE FB !!!!!!!!');
            /* Web Push Notif */
              const messaging = getMessaging();
              onMessage(messaging, (payload) => {
                if (payload && payload.notification) {
                  notificationStore.updateCurrentNotification(payload).then((currentNotification) => {
                    activeNotification.value = true;
                    notify(currentNotification);
                    emitter.emit('refreshNotifications', 'push');
                  });
                } else {
                  activeNotification.value = false;
                }
                //
                // ...
              }, e => {
                console.log(e);
              });
              getToken(messaging, { vapidKey: navStore.vapidKey }).then((currentToken) => {
                if (currentToken && userStore.userObj.loggedInUser && !userStore.userObj.isMasqueraded && process.env.NODE_ENV === 'production') {
                  userStore.updateFbToken(currentToken, source.value).then((fbToken) => {
                    if (fbToken) {
                      updateFbTokenOnLogin(navStore.apiUrl, navStore.authToken, userStore.userObj.token, currentToken, source.value).then((res) => {
                        //console.log('Upd FB on web server: ', res);
                      });
                    }
                  });

                } else {
                  // Show permission request UI
                  //console.log('No registration token available. Request permission to generate one.');
                  // ...
                }
              }, e => {
                console.log(e);
              });
            /* Web Push Notif */
          }

        }
        if (isNativeApp.value) {
          /* App Push Notif */
          registerAppPush(isUserLoggedIn);
          /* App Push Notif */
        }
      }

      const isLoginSessionExpired = async () => {
        if(userStore.userObj && userStore.userObj.id) {
          let currentUserId = userStore.userObj && userStore.userObj.id;
          let isValidUser = await getCurrentUser(navStore.apiUrl, navStore.authToken, userStore.userObj.token, currentUserId);
          if(currentUserId !== userStore.userObj.id) await getCurrentUser(navStore.apiUrl, navStore.authToken, userStore.userObj.token, userStore.userObj.id);
          if(isValidUser && !isValidUser.success) {
            if(isValidUser.error === "Signature has expired") {
              const response = await logoutUser(navStore.apiUrl, navStore.authToken, userStore.userObj.token);
              await router.push('/');
              emitter.emit('toggleSideMenuEvent', false);
            }
          }
        }
      };

      onMounted(async () => {
        if (isNativeApp.value) await PushNotifications.removeAllListeners();
        await generateFirebaseTokens(userStore.userObj.loggedInUser);
        await isLoginSessionExpired();
      });

      return {
        formNotificationClass,
        formTitleNotificationClass,
        formTextNotificationClass,
        formCloseNotificationClass,
        duration,
        activeNotification,
        activeFormNotification,
        activeBanner,
        notificationStore,
        position,
        closeNotification
      };
    },
  });
</script>

<style scoped>

.vue-notification-group {
    z-index: 25000;
}

</style>
