<template>
    <div class="h-full flex flex-col justify-center items-center gap-2.5 px-8 md:px-16 py-4 rounded-[20px] bg-white z-[9]"
      >
        <div class="flex flex-col justify-start items-start  w-full gap-6">
          <div class="flex flex-row justify-start items-start relative gap-2">
              <p class="flex justify-start text-3xl md:text-5xl font-bold text-left text-gray-900">
                Check your email
              </p>
          </div>
          <p class="w-full text-xl text-left text-gray-900">
            We sent a password reset link to: <strong>{{ email }}</strong>
          </p>
            <a :href="`mailto:${email}`" class="hidden w-full"><grid-button variant="primary" label="Open Email App" textLabelClass="px-0" size="large" customClass="w-full"></grid-button></a>
            <div class="flex flex-col md:flex-row justify-start items-start w-full gap-2">
              <span class="text-gray-900 whitespace-nowrap"
                >Didn’t receive the email?</span
              >
              <grid-button @click="(e) => handleForgotPassword(e)" variant="link" label="Click here to resend" textLabelClass="p-0 text-[16px] font-regular text-primary-900 hover:font-medium" size="large" customClass="w-auto !p-0 underline"></grid-button>
            </div>
        </div>
      </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import GridButton from "@/components/ui/GridButton";
import { useNavStore } from "@/stores/nav";
import { useRouter } from "vue-router";
import { modalController } from "@ionic/vue";
import ForgotPasswordModal from "@/components/ui/modals/ForgotPasswordModal";

export default defineComponent({
  name: "ForgotPasswordModal",
  components: { GridButton },
  props: {
    email: {
        type: String,
        default: () => "test@123.com"

    },
  },
  setup() {
    const router = useRouter();
    const navStore = useNavStore();

    const openForgotPasswordModal = async () => {
      const _modal = await modalController.create({
        component: ForgotPasswordModal, 
        id:"ForgotPasswordModal",
        componentProps: {
          title: 'New Title'
        },
      });
      return _modal.present();
    };

    return {
      router,
      navStore,
      handleForgotPassword(e) {
          e.preventDefault()
          modalController.dismiss().then(() => {
            openForgotPasswordModal();    
          });
      }
    }
  }
});
</script>

<style scope>

@media only screen and (max-width: 768px) {
  #CheckEmailModal {
    padding: 1rem;
  }

}

#CheckEmailModal {
  --border-radius: 16px!important;
  --height: 17rem;
}

ion-checkbox {
  --border-color: #9DA2B3;
}

</style>