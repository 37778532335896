<template>
    <div class="w-full h-full absolute left-0 lg:left-[65%] xl:left-3/4 z-[1]">
      <div class="w-full h-full">
        <svg
          width="354"
          height="256"
          viewBox="0 0 354 216"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="filter: drop-shadow(0px 16.464380264282227px 20.580474853515625px rgba(0,0,0,0.1)) drop-shadow(0px 8.232190132141113px 8.232190132141113px rgba(0,0,0,0.04));"
          preserveAspectRatio="none"
        >
          <g filter="url(#filter0_dd_3590_37615)">
            <rect x="21" y="37" width="312" height="141" rx="11.5251" fill="#206BFA"></rect>
            <path
              d="M21.0006 6.13942V51.9904C21.0006 53.0426 21.9742 53.8248 23.0017 53.5982L73.2898 42.5052C74.6941 42.1954 75.0543 40.3606 73.8713 39.543L23.5832 4.78501C22.4911 4.0302 21.0006 4.81187 21.0006 6.13942Z"
              fill="#206BFA"
            ></path>
          </g>
          <defs>
            <filter
              id="filter0_dd_3590_37615"
              x="0.419525"
              y="0.37414"
              width="353.161"
              height="214.671"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_3590_37615"
                result="shape"
              ></feBlend>
            </filter>
          </defs>
        </svg>
        <div
          class="w-[312px] flex flex-col justify-start items-start absolute left-7 top-10 gap-2 p-6"
        >
          <p
            class="w-full text-base font-bold text-left text-white"
          >
            New toolset available
          </p>
          <p class="w-full text-sm text-left text-white">
            Your new Cashflow functionalities are available on the menu
          </p>
          <div
            @click="onAction"
            class="flex flex-col justify-start items-center w-full relative gap-2 cursor-pointer"
          >
            <p
              class="w-full pt-2 text-base font-medium text-left text-white"
            >
              Got it
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import GridButton from "@/components/ui/GridButton";
import DynamicHeroIcon from "@/components/icons/DynamicHeroIcon";
import useOnboarding from "@/apis/useOnboardingApi.js";
import { useUserStore } from "@/stores/user";
import { useNavStore } from "@/stores/nav";
import useIdentification from "@/apis/sheets/useIdentification.js";

export default {
    components: {

    },
   props: {
    id: {
        type: Number
    },
    onAction: {
        type: Function
    },
  },
  setup(props) {


    return {  };
  },
};
</script>