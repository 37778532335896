import { defineStore } from 'pinia'
import { ref, inject } from "vue";

export const useFilterStore = defineStore("filter", () => {

    const activeFilters = ref({});

    const clearFilters = (name) => {
        activeFilters.value = {};
    }

    return { activeFilters, clearFilters }
});