<template>
    <ion-content :fullscreen="true" :scrollY="true">
      <div>
        sdfdfsdfsdf
      </div>
      <ion-list id="notifications-list" class="flex flex-col justify-center items-center py-5">
        <ion-item 
          v-for="(notification, index) in notifications" 
          lines="none" 
          :key="index" 
          class="flex flex-row pl-8 my-[1rem]">
          <web-push-notification 
            :id="notification.id && notification.id" 
            :title="notification.title && notification.title" 
            :text="notification.text && notification.text" 
            :date="notification.data && notification.data.date && notification.data.date"
            :sentiment="notification.data && notification.data.sentiment && notification.data.sentiment" 
            :link="notification.data && notification.data.link && notification.data.link"
            :closeNotification="closeNotification">
          </web-push-notification>
        </ion-item>
      </ion-list>
    </ion-content>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import GridButton from "@/components/ui/GridButton";
import GridInput from "@/components/ui/GridInput";
import useAuthApi from "@/apis/useAuthApi.js";
import { useUserStore } from "@/stores/user";
import { useNavStore } from "@/stores/nav";
import { useRoute, useRouter } from "vue-router";
import { modalController, IonContent, IonList, IonItem } from "@ionic/vue";
import WebPushNotification from "@/components/notifications/WebPushNotification";
import ForgotPasswordModal from "@/components/ui/modals/ForgotPasswordModal";
import ProfileInitialsIcon from '@/components/icons/ProfileInitialsIcon';

export default defineComponent({
  name: "NotificationModal",
  components: { //GridButton, 
  //GridInput 
    IonContent, 
    IonList, 
    IonItem,
    WebPushNotification
  },
  props: {
    title: {
        type: String
    },
    notifications: {
        type: Array
    },
    description: {
        type: String
    },
    imgUrl: {
        type: String
    },
  },
  setup(props) {

    const { loginUser } = useAuthApi();
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const navStore = useNavStore();
    const email = ref("");
    const password = ref("");
    const warningText = ref(false);

    const closeNotification = (id) => {
      console.log('closeNotification id: ', id);
    }


    return {
      closeNotification,
      route,
      navStore,
      userStore,
      email,
      password,
      warningText,
    }
  }
});
</script>

<style scope>

@media only screen and (max-width: 768px) {
  #NotificationModal {
    padding: 1rem;
  }

}

#NotificationModal {
  --border-radius: 16px!important;
  --height: auto;
  --min-height: 20rem;
}

#NotificationModal .ion-page {
  display: contents;
}

ion-modal {
  --border-radius: 16px!important;
  --height: 33rem;
}

ion-checkbox {
  --border-color: #9DA2B3;
}

</style>