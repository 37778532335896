<template>
  <div :class="topClass">
    <div
    v-show="showNotification"
    :class="[baseClass, customClass]"
    >
    <div
        :class="[
            'w-1.5 h-full relative overflow-hidden rounded-tl-lg rounded-bl-lg bg-gradient-to-b',
            gradientClass, 
            sentiment === 'neutral' ? 'from-[#90c2ff] to-[#2e8bff]' : 
            sentiment === 'alert' ? 'from-red-300 to-[#f53939]' :
            sentiment === 'warning' ? 'from-amber-200 to-amber-500' : 
            sentiment === 'positive' ? 'from-emerald-300 to-emerald-500' :
            'from-neutral-200 to-neutral-500'
        ]"
    ></div>
    <div class="flex flex-col justify-start items-start flex-grow relative gap-0.5 p-4">
        <div class="flex justify-start items-center gap-1 w-full">
        <div class="flex justify-between items-center flex-grow relative">
            <p class="flex flex-row text-[14px] md:text-[16px] font-bold text-left text-[#141727]">
            {{ title }}
            <span v-show="unRead" class="pl-1">
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex-grow-0 flex-shrink-0"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <circle cx="4" cy="4" r="4" fill="#4D89FB"></circle>
                </svg>
            </span>
            </p>
            <div v-show="closeNotification" @click="closePushNotification(id)" class="flex justify-start items-start relative gap-2.5 cursor-pointer">
              <XIcon data-value="[close-btn]" class="w-5 stroke-[2px]"/>
            </div>
        </div>
        </div>
        <p class="text-[14px] md:text-[16px] text-left text-[#252f40]">
        {{ text }}
        </p>
        <div class="flex flex-row justify-between gap-2 pt-[3px] w-full">
            <p class="text-[12px] text-left text-[#6c757d]">
            {{ date }} 
            </p>
            <strong v-show="link" class="cursor-pointer">
            <span @click="redirectNotificationLink(link)" class="" :data-value="'[notifications - ' + link + ']'">
                <ExternalLinkIcon class="w-5 h-5" />
            </span>
            </strong>
        </div>

    </div>
    </div>
  </div>
</template>

<script>
import { IonContent, IonList, IonItem } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { modalController } from "@ionic/vue";
import { notify } from "@kyvg/vue3-notification";
import TopBannerNotification from "@/components/notifications/TopBannerNotification";
import { useNavStore } from "@/stores/nav";
import { useUserStore } from "@/stores/user";
import { useNotificationStore } from "@/stores/notification";
import useNotificationsApi from "@/apis/useNotificationsApi.js";
import { ExternalLinkIcon, XIcon } from '@heroicons/vue/outline';
import { useRouter } from "vue-router";

  export default defineComponent({
    name: "WebPushNotification",
    components: {
      ExternalLinkIcon,
      XIcon
    },
    props: {
      id: {
        type: Number
      },
      title: {
        type: String
      },
      text: {
        type: String
      },
      date: {
        type: String
      },
      link: {
        type: String
      },
      sentiment: {
        type: String
      },
      unRead: {
        type: Boolean,
        default: () => false
      },
      showNotification: {
        type: Boolean,
        default: () => true
      },
      closeNotification: {
        type: Function
      },
      topClass: {
        type: String,
        default: () => "flex flex-row w-full justify-end"
      },
      baseClass: {
        type: String,
        default: () => "flex justify-center items-start relative rounded-lg bg-white h-full md:min-w-[30rem] md:max-w-[30rem] shadow-2xl mb-2"
      },
      customClass: {
        type: String,
        default: () => ""
      },
      gradientClass: {
        type: String,
        default: () => ""
      }
    },
    setup(props) {
        const router = useRouter();
        const notificationStore = useNotificationStore();
        const userStore = useUserStore();
        const navStore = useNavStore();
        const { getAllNotifications, getLatestNotifications, markNotificationAsRead } = useNotificationsApi();

        const notifications = ref([]);

        const redirectNotificationLink = async (path) => {
          const popup = await modalController.getTop();
          if(popup) await modalController.dismiss();
          //router.push(path);
          window.location.href=path
        }

        const closePushNotification = async (notificationId) => {
          props.closeNotification(notificationId);
          /*markNotificationAsRead(navStore.apiUrl, navStore.authToken, userStore.userObj.token, notificationId).then((response) => {
            console.log('response: ', response);
            console.log('notificationId: ', notificationId);

          })*/
        }
      return {
        router,
        navStore,
        redirectNotificationLink,
        closePushNotification
      };
    },
  });
</script>

<style scoped>

</style>