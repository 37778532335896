<template>
<div
        class="h-full flex flex-col justify-center items-center gap-2.5 px-8 md:px-16 py-4 rounded-[20px] bg-white z-[9]"
      >
        <div class="flex flex-col justify-start items-start  w-full gap-6">
          <div class="flex flex-row justify-start items-start relative gap-2">
              <p class="flex justify-start text-3xl md:text-5xl font-bold text-left text-gray-900">
                Forgot your Password?
              </p>
          </div>
          <p class="w-full text-xl text-left text-gray-900">
            No worries, we’ll send you the reset instructions!
          </p>

          <div class="flex flex-col justify-center items-center self-stretch ">
            <grid-input v-model="email" label="Email" placeholderText="name@company.ie"></grid-input>
          </div>
            <grid-button @click="(e) => handleForgotPassword(e)"  variant="primary" label="Reset Password" textLabelClass="px-0" size="large" customClass="w-full"></grid-button>
            <div v-show="warningText">
              <div class="flex flex-row relative md:absolute">
                <p class="text-gray-500 pr-2">*</p>
                <p class="text-gray-500 first-letter:capitalize text-[14px]">{{ warningText }}</p></div>
            </div>
        </div>
      </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import GridButton from "@/components/ui/GridButton";
import GridInput from "@/components/ui/GridInput";
import { useNavStore } from "@/stores/nav";
import { useRouter } from "vue-router";
import { modalController } from "@ionic/vue";
import CheckEmailModal from "@/components/ui/modals/CheckEmailModal";
import useAuthApi from "@/apis/useAuthApi.js";

export default defineComponent({
  name: "ForgotPasswordModal",
  components: { GridButton, GridInput },
  setup() {
    const { forgotPassword } = useAuthApi();
    const router = useRouter();
    const navStore = useNavStore();
    const email = ref("");
    const warningText = ref(false);

    const openCheckEmailModal = async (emailText) => {
      const _modal = await modalController.create({
        component: CheckEmailModal, 
        id:"CheckEmailModal",
        componentProps: {
          title: 'New Title',
          email: emailText
        },
      });
      return _modal.present();
    };

    return {
      router,
      navStore,
      email,
      warningText,
      handleForgotPassword(e) {
          e.preventDefault()
          if( (email.value.length < 1) ) {
              warningText.value = 'Email field cannot be empty';
          } else {
            forgotPassword(navStore.apiUrl, navStore.authToken, email.value).then((response) => {
              if (response[1] == 200) {
                modalController.dismiss().then(() => {
                  openCheckEmailModal(email.value);    
                });
              } else {
                warningText.value = 'Password reset did not work, please check your email and try again.';
              }

            })
          }
      }
    }
  }
});
</script>

<style scope>

@media only screen and (max-width: 768px) {
  #ForgotPasswordModal {
    padding: 1rem;
  }

}

#ForgotPasswordModal {
  --border-radius: 16px!important;
  --height: 25rem;
}

ion-checkbox {
  --border-color: #9DA2B3;
}

</style>