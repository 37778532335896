import { defineStore } from 'pinia'
import { ref, inject } from "vue";
import { notify } from "@kyvg/vue3-notification";

export const useNotificationStore = defineStore("notification", () => {

    const notificationObj = ref({
        id: "default-msg",
        title: "Finish your application!",
        text: "You have an application in drafts, click the banner to finish the application now.",
        data: {
            date: "Wed Aug 17 2022",
            link: "/partners",
        }
    });

    const unReadNotifications = ref();

    const allNotifications = ref([]);

    const latestNotifications = ref([]);

    const currentBannerObj = ref({});

    const currentNotificationObj = ref({});

    const getNotifications = async () => {
        notificationObj.value = {};
    }

    const gridUsersWhiteList = ref(['rui@gridfinance.ie',
        'vinny@gridfinance.ie']);

    const updateAllNotifications = async (notfications) => {
        allNotifications.value = notfications;
    }

    const updateUnreadNotifications = async (amount) => {
        unReadNotifications.value = amount;
    }

    const updateLatestNotifications = async (notfications) => {
        latestNotifications.value = notfications;
    }

    const callNotification = async (notification) => {
        if (notification.data) {
            notify({
                id: notification.id,
                title: notification.data.title,
                text: notification.data.text,
                duration: notification.data.duration ? notification.data.duration : 5000
            });
        }
    }

    const updateCurrentBanner = async (obj) => {
        const banner = { data: {} };
        banner.title = obj.title && obj.title;
        banner.text = obj.text && obj.text;
        banner.id = obj.id && obj.id;
        banner.data.date = obj.data && obj.data.created_at && obj.data.created_at;
        banner.data.link = obj.data && obj.data.link && obj.data.link;
        banner.data.sentiment = obj.data && obj.data.sentiment && obj.data.sentiment;
        banner.data.kind = "banner";
        return banner
    }

    const updateCurrentNotification = async (obj) => {
        const notification = { data: {} };
        const data = await JSON.parse(obj.data.payload).data;
        console.log('data: ', data);
        notification.title = obj.notification.title && obj.notification.title;
        notification.text = obj.notification.body && obj.notification.body;
        notification.id = data && data.id;
        notification.data.date = data && data.date;
        notification.data.link = data && data.link;
        notification.data.sentiment = data && data.sentiment;
        notification.data.kind = "notification"
        return notification
    }

    return { gridUsersWhiteList, unReadNotifications, allNotifications, latestNotifications, notificationObj, currentBannerObj, currentNotificationObj, updateUnreadNotifications, getNotifications, updateAllNotifications, updateLatestNotifications, updateCurrentBanner, updateCurrentNotification, callNotification }
});