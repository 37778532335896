
<template>
<a
    :id="id"
    v-on="$attrs"
    :class="[baseClass, variantClass, sizeClass, disabledClass, customClass, activeClick && '!pointer-events-none']"
    @mouseover="changeFillColor"
    @mouseout="resetFillColor"
    :href="externalUrl && externalUrl"
    @click="delay"
    :data-value="dataAttribute && '[' + dataAttribute.replace(/\s+/g, '-').toLowerCase() + ']'"
    :target="targetBlank ? '_blank' : '_self'"
    >
    <span v-show="!loading && !loadingFinished" class="flex flex-row">
        <span class="m-auto" v-show="iconType === 'left' || iconType === 'double'">
            <component v-show="icon" :is="btnIcon" :fillColor="iconColor" :iconClass="iconClass ? iconClass : defIconClass" />
        </span>
        <span :class="labelClass" v-show="iconType === 'left' || iconType === 'right' || iconType === 'double'">{{ label }}</span>
        <span class="m-auto" v-show="iconType === 'right' || iconType === 'double'">
            <component v-show="icon" :is="btnIcon" :fillColor="iconColor" :iconClass="iconClass ? iconClass : defIconClass" />
        </span>
    </span>
    <span class="flex flex-row" v-show="!loading && !loadingFinished && iconType === 'standalone'"><component v-show="icon" :is="btnIcon" :fillColor="iconColor" :iconClass="iconClass ? iconClass : defIconClass" /></span>
    <span class="flex flex-row" v-show="!loading && !loadingFinished && iconType === 'off'"><span :class="labelClass"><a :title="label">{{ label }}</a></span></span>
    <span v-show="loading" class="flex flex-row w-full h-full justify-center">
        <span class="p-0">
            <grid-loader :fillColor="loaderColor" :iconClass="loaderClass" ></grid-loader>
        </span>
    </span>
    <span v-show="loadingFinished" class="flex flex-row w-full h-full justify-center">
        <span class="p-0">
            <grid-loader-finished :fillColor="loaderColor"></grid-loader-finished>
        </span>
    </span>
</a>
</template>

<script>
import { ref, computed, defineAsyncComponent } from 'vue';
import GridBtnIcon from '@/components/icons/GridBtnIcon';
import GridLoader from '@/components/icons/GridLoader';
import GridLoaderFinished from '@/components/icons/GridLoaderFinished';

export default {
  components: {
    GridBtnIcon,
    GridLoader,
    GridLoaderFinished,

  },
 props: {
    externalUrl: {
        type: String
    },
    label: {
        type: String,
        default: () => "Label"
    },
    baseClass: {
        type: String,
        default: () => "flex justify-center items-center relative cursor-pointer"
    },
    customClass: {
        type: [String, Array],
        default: () => ""
    },
    textLabelClass: {
        type: String
    },
    size: {
        type: String,
        default: () => "medium"
    },
    variant: {
        type: String,
        default: () => "primary"
    },
    disabled: {
        type: Boolean,
        default: () => false
    },
    loading: {
        type: Boolean,
        default: () => false
    },
    loadingFinished: {
        type: Boolean,
        default: () => false
    },
    targetBlank: {
        type: Boolean,
        default: () => false
    },
    type: {
        type: String,
        default: () => "button"
    },
    icon: {
        type: String,
        default: () => "GridBtnIcon"
    },
    iconType: {
        type: String,
        default: () => "off"
    },
    iconClass: {
        type: String
    },
    dataAttribute: {
        type: String
    },
    loaderClass: {
        type: String,
        default: () => ""
    },
    id: {
        type: String
    },
 },
 setup(props) {
    const activeClick = ref(false);
    const delay = () => {
        activeClick.value = true;
        setTimeout(() => { activeClick.value = false; }, 3000)
    }
    var loaderColor = ref("#FFFFFF");
    var iconColor = ref("#F9FAFB");
    var variantClass = '';
    var sizeClass = '';
    var labelClass = '';
    var iconDefaultColor = '';
    var defIconClass= '';
    var iconHoverColor = '';

    switch (props.variant) {
        case 'primary':
            variantClass = 'text-white font-semibold bg-primary-900 bg-gradient-to-r hover:from-primary-900 hover:to-primary-1000';
            iconDefaultColor = "#F9FAFB";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#F9FAFB";
            loaderColor = "#FFFFFF";
            break
        case 'outline':
            variantClass = 'text-neutral-900 font-semibold bg-white border-neutral-300 border-solid border hover:text-primary-900 hover:bg-primary-100 hover:border-primary-900';
            iconDefaultColor = "#111827";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#206BFA";
            loaderColor = "#206BFA";
            break
        case 'outline-focus':
            variantClass = 'text-primary-900 font-semibold bg-[#f4f8ff] border-primary-900 border-solid border-2';
            iconDefaultColor = "#206bfa";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#206BFA";
            loaderColor = "#206BFA";
            break
        case 'danger':
            variantClass = 'text-neutral-100 font-semibold bg-danger-500 hover:bg-danger-700';
            iconDefaultColor = "#F9FAFB";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#F9FAFB";
            loaderColor = "#FFFFFF";
            break
        case 'success':
            variantClass = 'text-neutral-900 font-semibold bg-white border-neutral-300 border-solid border hover:text-primary-900 hover:bg-primary-100 hover:border-primary-900';
            iconDefaultColor = "#15803d";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#206BFA";
            loaderColor = "#206BFA";
            break
        case 'outline-danger':
            variantClass = 'text-danger-500 font-semibold bg-white border-danger-500 border-solid border hover:text-danger-500 hover:bg-danger-100 hover:border-danger-500';
            iconDefaultColor = "#EF4444";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#EF4444";
            loaderColor = "#EF4444";
            break
        case 'light':
            variantClass = 'text-secondary-200 font-semibold bg-secondary-100';
            iconDefaultColor = "#CED0D9";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#CED0D9";
            loaderColor = "#FFFFFF";
            break
        case 'outline-light':
            variantClass = 'text-white opacity-80 font-semibold bg-transparent border-white border-solid border hover:opacity-100';
            iconDefaultColor = "#D1D5DB";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#D1D5DB";
            loaderColor = "#D1D5DB";
            break
        case 'dark':
            variantClass = 'text-white font-semibold bg-black';
            iconDefaultColor = "#F9FAFB";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#F9FAFB";
            loaderColor = "#FFFFFF";
            break
        case 'link':
            variantClass = 'text-secondary-500 hover:text-gray-900 hover:font-bold bg-transparent hover:tracking-normal flex-grow-0 flex-shrink-0 text-base lg:text-xs font-medium text-left';
            iconDefaultColor = "#D1D5DB";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#D1D5DB";
            loaderColor = "#D1D5DB";
            break
        case 'link-light':
            variantClass = 'text-white opacity-80 hover:text-white hover:font-bold bg-transparent hover:tracking-normal flex-grow-0 flex-shrink-0 text-base lg:text-xs font-medium text-left';
            iconDefaultColor = "#ffffff";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#ffffff";
            loaderColor = "#ffffff";
            break
        case 'dropdown-link':
            variantClass = 'text-secondary-500 hover:text-gray-900 hover:font-bold bg-transparent flex-grow-0 flex-shrink-0 text-base lg:text-xs font-medium text-left';
            iconDefaultColor = "#7A7171";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#111827";
            loaderColor = "#111827";
            break
        case 'active-link':
            variantClass = 'text-gray-900 font-bold bg-transparent flex-grow-0 flex-shrink-0 text-base lg:text-xs font-bold text-left';
            iconDefaultColor = "#D1D5DB";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#D1D5DB";
            loaderColor = "#D1D5DB";
            break
        case 'active-link-light':
            variantClass = 'text-white font-bold bg-transparent flex-grow-0 flex-shrink-0 text-base lg:text-xs font-bold text-left';
            iconDefaultColor = "#D1D5DB";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#D1D5DB";
            loaderColor = "#D1D5DB";
            break
        default:
            variantClass = 'bg-primary-900 font-semibold bg-gradient-to-r hover:from-primary-900 hover:to-primary-1000';
            iconDefaultColor = "#F9FAFB";
            iconColor.value = iconDefaultColor;
            iconHoverColor = "#F9FAFB";
            loaderColor = "#FFFFFF";
            break
    }
    switch (props.size) {
        case 'small':
            sizeClass = props.iconType === 'standalone' ? 'p-2.25 text-sm rounded' : 'px-2.5 py-1.5 text-sm rounded'
            defIconClass = 'flex-grow-0 flex-shrink-0 w-4 h-4 relative';
            labelClass = props.textLabelClass ? props.textLabelClass : 'px-1.5';
            break
        case 'medium':
            sizeClass = props.iconType === 'standalone' ? 'p-2.75 text-base rounded-md' : 'px-4 py-2 text-base rounded-md'
            defIconClass = 'flex-grow-0 flex-shrink-0 w-5 h-5 relative';
            labelClass = props.textLabelClass ? props.textLabelClass : 'px-2';
            break
        case 'large':
            sizeClass = props.iconType === 'standalone' ? 'p-3 text-lg rounded-lg' : 'px-6 py-2.5 text-lg rounded-lg'
            defIconClass = 'flex-grow-0 flex-shrink-0 w-6 h-6 relative';
            labelClass = props.textLabelClass ? props.textLabelClass : 'px-2.5';
            break
        case 'full':
            sizeClass = props.iconType === 'standalone' ? 'w-full p-3 text-lg rounded-lg' : 'w-full px-6 text-lg rounded-lg'
            defIconClass = 'flex-grow-0 flex-shrink-0 w-6 h-6 relative';
            labelClass = props.textLabelClass ? props.textLabelClass : 'p-2.5';
            break
        default:
            sizeClass = props.iconType === 'standalone' ? 'p-2.75 text-base rounded-md' : 'px-4 py-2 text-base rounded-md'
            defIconClass = 'flex-grow-0 flex-shrink-0 w-5 h-5 relative';
            labelClass = props.textLabelClass ? props.textLabelClass : 'p-2';
            break
    }

    const disabledClass = props.disabled ? 'cursor-not-allowed hover:border-neutral-300' : '';

    const btnIcon = computed (() => defineAsyncComponent(() => import(`@/components/icons/${props.icon}.vue`)));

    const changeFillColor = () => {
        iconColor.value = props.disabled ? '#6B7280' : iconHoverColor;
    }

    const resetFillColor = () => {
        iconColor.value = iconDefaultColor
    }

    const redirectToExternalUrl = () => {
        if (props.externalUrl) {
            window.location.href = props.externalUrl
        }
    }

    return { delay, activeClick, variantClass, sizeClass, disabledClass, iconColor, defIconClass, labelClass, btnIcon, loaderColor, changeFillColor, resetFillColor, redirectToExternalUrl };
 },
};
</script>

<style>

</style>
