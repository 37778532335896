<template>
      <form
        @keyup.enter="(e) => handleLogin(e)"
        class="h-full flex flex-col justify-center items-center gap-2.5 px-8 md:px-16 py-4 rounded-[20px] bg-white z-[9]"
      >
        <div class="flex flex-col justify-start items-start  w-full gap-6">
          <div class="flex flex-row justify-start items-start relative gap-2">
              <p class="flex justify-start text-3xl md:text-5xl font-bold text-left text-gray-900">
                Login to your account
              </p>
          </div>
          <div class="flex flex-col justify-center items-center self-stretch ">
            <grid-input v-model="email" label="Email" placeholderText="name@company.ie"></grid-input>
          </div>
          <div class="flex flex-col justify-center items-center self-stretch ">
            <grid-input v-model="password" type="password" icon="EyeIcon" iconType="right" label="Password" placeholderText="*********"></grid-input>
          </div>
          <div class="flex flex-col md:flex-row justify-end items-start w-full">
            <div class="hidden justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
              <p class="flex-grow-0 flex-shrink-0 text-sm text-left text-gray-900">Remember me</p>
            </div>
            <div class="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
              <span
                  @click="(e) => redirectToForgotPassword(e)"
                  class="underline p-0 text-[14px] font-semibold text-primary-900 hover:font-medium cursor-pointer"
                  ><p class="w-full" title="Forgot your password?">Forgot your password?</p>
              </span>
            </div>
          </div>
            <grid-button tabindex="0" :loading="loading" :loadingFinished="loadingFinished" @click="(e) => handleLogin(e)" variant="primary" label="Login to your Account" textLabelClass="px-0" size="large" customClass="w-full"></grid-button>
            <div class="flex flex-row justify-center items-center w-full gap-2">
              <span class="text-gray-900 whitespace-nowrap"
                >Don’t have an account?</span
              >
              <span
                  @click="(e) => redirectToSignup(e)"
                  class="underline p-0 text-[16px] font-semibold text-primary-900 hover:font-medium cursor-pointer"
                  ><p class="w-full" title="Sign up">Sign up</p>
              </span>

            </div>
            <div v-show="warningText">
              <div class="flex flex-row relative md:absolute">
                <p class="text-gray-500 pr-2">*</p>
                <p class="text-gray-500 first-letter:capitalize text-[14px]">{{ warningText }}</p></div>
            </div>
        </div>
      </form>
</template>

<script>

import { Capacitor } from '@capacitor/core';
import { defineComponent, ref, inject } from 'vue';
import GridButton from "@/components/ui/GridButton";
import GridInput from "@/components/ui/GridInput";
import useAuthApi from "@/apis/useAuthApi.js";
import { useUserStore } from "@/stores/user";
import { useNavStore } from "@/stores/nav";
import { useRouter } from "vue-router";
import { modalController, menuController } from "@ionic/vue";
import ForgotPasswordModal from "@/components/ui/modals/ForgotPasswordModal";
import useNotificationsApi from "@/apis/useNotificationsApi.js";
import { useOnboardingStore } from "@/stores/onboarding";

export default defineComponent({
  name: "LoginModal",
  components: { GridButton, GridInput },
  setup() {
    const isNativeApp = ref(Capacitor.isNativePlatform());
    const emitter = inject('emitter');
    const loading = ref(false);
    const loadingFinished = ref(false);
    const { loginUser } = useAuthApi();
    const { updateFbTokenOnLogin } = useNotificationsApi();
    const router = useRouter();
    const userStore = useUserStore();
    const navStore = useNavStore();
    const email = ref("");
    const password = ref("");
    const warningText = ref(false);
    const onboardingStore = useOnboardingStore();

    const openForgotPasswordModal = async () => {
      const _modal = await modalController.create({
        component: ForgotPasswordModal,
        id:"ForgotPasswordModal",
        componentProps: {
          title: 'New Title'
        },
      });
      return _modal.present();
    };

    const redirectToForgotPassword = () => {
        modalController.dismiss().then(() => {
          openForgotPasswordModal();
        });
    }

    const redirectToAnalytics = async () => {
      loadingFinished.value = true;
      userStore.userObj.loggedInUser = true;
      await navStore.showSideMenu(true);
      //router.push('/analytics/dashboard');
      emitter.emit('toggleSideMenuEvent');
      window.location.href="/analytics/dashboard"
    }

    const redirectToSignup = async (e) => {
      e.preventDefault();
      await userStore.removeUserFromLocalStorage('GridUser');
      await onboardingStore.updateCustomerOnboardingIndex(0);
      await modalController.dismiss();
      if(isNativeApp.value) {
        //router.push('/mobile/landing');
        window.location.href="/mobile/landing"
      } else {
        //router.push('/onboarding/customer');
        window.location.href="/onboarding/customer"
      }
    }

    const redirectToPath = (path) => {
      var selectedIndex = navStore.pages.findIndex(
        (page) => page.url === path.toLowerCase()
      );
      navStore.updateIndex(selectedIndex).then(() => {
        //router.push(path);
        window.location.href=path
        modalController.dismiss();
      });
    }

    const loginRedirect = async (path) => {
        return new Promise((resolve, reject) => {
          //resolve(router.push(path));
          resolve(window.location.href=path)
        });
    }
    const onboardCompleteLoginRedirect = async () => {
      if(userStore.userObj.analyticsBeta) {
        return new Promise((resolve, reject) => {
          //resolve(router.push('/home'));
          if(userStore.userObj.hasLegal) { 
            resolve(window.location.href='/home')
          } else {
            resolve(window.location.href='/onboarding/company') 
          } 
        });
      } else {
        return new Promise((resolve, reject) => {
          if(userStore.userObj.hasFinance) {
            //resolve(router.push('/finance/dashboard'));  
            resolve(window.location.href='/finance/dashboard')          
          } else {
            if(userStore.userObj.hasLegal) { 
              resolve(window.location.href='/home')
            } else {
              resolve(window.location.href='/onboarding/company') 
            } 
          }
        });        
      }
    }

    const handleLogin = async (e) => {
          loading.value = true;
          e.preventDefault()
          if( (email.value.length < 1) || (password.value.length < 1) ) {
              warningText.value = 'all fields required';
          } else {
            if (password.value.length < 8) {
                warningText.value = 'min 8 characters required for password';
            } else {
              let response = await loginUser(navStore.apiUrl, navStore.authToken, email.value, password.value);
              loading.value = false;
              if (response.success) {
                userStore.userObj.loggedInUser = true;
                loadingFinished.value = true;
                await userStore.identifyUserOnHeap(userStore.userObj.id);

              if(userStore.userObj.isSignatory && navStore.path && navStore.path === '/account/identity_documents?path=loan_application') {
                let redirectPath = navStore.path;
                await navStore.removeUrlPath(navStore.path);
                await loginRedirect(redirectPath);
              }
              if(userStore.userObj.isSignatory) await loginRedirect('/account/identity_documents?path=loan_application');
              if (isNativeApp.value || userStore.userObj.isBorrower) {
                  let onboardingCompanyComplete = await onboardingStore.isOnboardingComplete('company');
                  if(onboardingCompanyComplete) {
                    console.log('onboardingCompanyComplete: ', onboardingCompanyComplete);
                    await onboardCompleteLoginRedirect();
                   // router.go();
                  } else {
                    await loginRedirect('/onboarding/company');
                   // router.go();
                  }
                  await modalController.dismiss();
                } else {
                  window.location.href = response.server;
                }

              } else {
                warningText.value = response.msg;
              }
            }
          }
      }

    return {
      isNativeApp,
      router,
      navStore,
      userStore,
      email,
      password,
      warningText,
      loading,
      loadingFinished,
      redirectToSignup,
      handleLogin,
      redirectToPath,
      redirectToForgotPassword,
      onboardCompleteLoginRedirect
    }
  }
});
</script>

<style scope>

@media only screen and (max-width: 768px) {
  #LoginModal {
    padding: 1rem;
  }

}

#LoginModal {
  --border-radius: 16px!important;
  --height: 33rem;
}

ion-modal {
  --border-radius: 16px!important;
  --height: 33rem;
}

ion-checkbox {
  --border-color: #9DA2B3;
}

</style>