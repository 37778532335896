import { Capacitor } from '@capacitor/core';
import { useUserStore } from "@/stores/user";
import { useNavStore } from "@/stores/nav";
import { useRouter } from "vue-router";
import { CapacitorHttp } from '@capacitor/core';

export default () => {

    const userStore = useUserStore();
    const navStore = useNavStore();
    const router = useRouter();
    const isNativeApp = Capacitor.isNativePlatform();

    const updateDisplayWelcomeScreen = async (apiUrl, token, userToken, companyId, displayBool) => {
        const options = {
            url: `${apiUrl}/api/v1/company/products_config/update_display_welcome_screen`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                company_id: companyId,
                display_welcome_screen: displayBool
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const startOnboardingState = async (apiUrl, token, userToken, lastSavedPath, journey) => {
        const options = {
            url: `${apiUrl}/api/v1/customer/onboarding_state/create`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                last_saved_path: lastSavedPath,
                journey: journey
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true,
                    onboardingPath: response.data.onboarding_path && response.data.onboarding_path
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const createOnboardingState = async (apiUrl, token, userToken, onboarding, company_id) => {
        const options = {
            url: `${apiUrl}/api/v1/onboarding`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                path: onboarding.path,
                type: onboarding.type,
                company_id: company_id && company_id,
                project_id: onboarding.project_id && onboarding.project_id
            }
        };

        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true,
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const startOnboardingState2 = async (apiUrl, token, userToken, onboarding, company_id) => {
        const options = {
            url: `${apiUrl}/api/v1/onboarding`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                path: onboarding.path,
                type: onboarding.type,
                company_id: company_id && company_id
            }
        };

        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true,
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const updateOnboardingState2 = async (apiUrl, token, userToken, onboarding, company_id) => {
        const options = {
            url: `${apiUrl}/api/v1/onboarding/${onboarding.id}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                path: onboarding.path,
                type: onboarding.type,
                company_id: company_id && company_id,
                project_id: onboarding.project_id && onboarding.project_id
            }
        };

        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true,
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const updateOnboardingState = async (apiUrl, token, userToken, lastSavedPath, journey) => {
        let params = { last_saved_path: lastSavedPath };
        if (journey && lastSavedPath == 'SelectProfile') params.journey = journey;

        const options = {
            url: `${apiUrl}/api/v1/customer/onboarding_state/update`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: params
        };


        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true,
                    onboardingPath: response.data.onboarding_path && response.data.onboarding_path,
                    error: response.error
                }
            } else {
                return {
                    success: false
                }
            }
        } catch (err) {
            return err;
        }
    };

    const confirmCompany = async (apiUrl, token, userToken) => {
        const options = {
            url: apiUrl + `/api/v1/analytics_data/last_updated`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);

            if (response.status === 200) {
                return response.data.sheets
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const getSignatoryProfiles = async (apiUrl, token, userToken, companyId, projectId) => {
        const options = {
            url: apiUrl + `/api/v1/company/signatory_profiles?company_id=${companyId}&project_id=${projectId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);

            if (response.status === 200) {
                return response.data
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const createSignatoryProfile = async (apiUrl, token, userToken, data) => {
        let fullName = data?.full_name?.split(' ');
        const options = {
            url: `${apiUrl}/api/v1/company/signatory_profiles`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                signatory_profile: {
                    company_id: data.company_id,
                    first_name: fullName && fullName[0],
                    last_name: fullName && fullName[1],
                    email: data.email,
                    phone_number: data.phone_number,
                    single_line_address: data.single_line_address,
                    docusign_company_role: data.docusign_company_role,
                    selected: data.selected && data.selected
                }
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true
                }
            } else {
                return {
                    success: false,
                    error: Object.keys(response.data.error).length > 0 ? `${Object.values(response.data.error)[0]}` : response.data.error,
                    errorKey: Object.keys(response.data.error).length > 0 && `${Object.keys(response.data.error)[0]}`
                }
            }
        } catch (err) {
            return err;
        }
    };

    const updateSignatoryProfile = async (apiUrl, token, userToken, data) => {
        const options = {
            url: `${apiUrl}/api/v1/company/signatory_profiles/${data.id}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                signatory_profile: {
                    email: data.email && data.email,
                    phone_number: data.phone_number && data.phone_number,
                    selected: data.selected && data.selected
                }
            }
        };


        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true
                }
            } else {
                return {
                    success: false,
                    error: Object.keys(response.data.error).length > 0 ? `${Object.values(response.data.error)[0]}` : response.data.error,
                    errorKey: Object.keys(response.data.error).length > 0 && `${Object.keys(response.data.error)[0]}`
                }
            }
        } catch (err) {
            return err;
        }
    };

    const createAccount = async (apiUrl, token, data, device) => {
        const options = {
            url: `${apiUrl}/api/v1/users?source=${device}&${navStore.utmTagsToString()}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                user: {
                    email: data.email.toLowerCase(),
                    password: data.password,
                    first_name: data.firstName,
                    last_name: data.lastName,
                    phone_number: data.phone,
                    accepted_tcs: 1
                },
                lead: { company_name: data.tradingName }
            }
        };


        try {
            const response = await CapacitorHttp.post(options);
            if (response.status === 200) {
                if (!isNativeApp) userStore.$cookies.set('logged_user', 'true');
                userStore.userObj = {
                    id: response.data.id,
                    name: response.data.name,
                    email: response.data.email,
                    phone: response.data.phone_number,
                    token: response.data.token,
                    firebase_tokens: response.data.firebase_tokens,
                    companies: response.data.companies,
                    initCompanyName: response.data.company_name,
                    activeCompany: response.data.companies ? response.data.companies[0] : {},
                    plaidReadyFlag: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.plaid_transactions_ready : false,
                    subscription: response.data.subscription && response.data.subscription,
                    gridScoreReadyFlag: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.grid_score_ready : false,
                    //analyticsBeta: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.beta_tester : false,
                    analyticsBeta: true,
                    merchantReadyFlag: response.data.companies && response.data.companies[0].products_config && response.data.companies[0].products_config.payable_cash_advance_loans_present ? response.data.companies[0].products_config.payable_cash_advance_loans_present : false,
                    profileCompletedFlag: response.data.profile_completed,
                    onBoardingComplete: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.onboarding_complete : false,
                    loanApplicationId: response.data.companies && response.data.companies[0].active_project_draft ? response.data.companies[0].active_project_draft.id : null,
                    accounts: response.data.plaid_accounts,
                    activeAccount: response.data.plaid_accounts ? response.data.plaid_accounts[0] : {},
                    onboardingPath: response.data.onboarding_path && response.data.onboarding_path,
                    loggedInUser: true,
                    isAdmin: response.data.group && response.data.group.id >= 500,
                    isBorrower: response.data.group && response.data.group.user_kind == 'borrower',
                    isSignatory: response.data.group && response.data.group.user_kind == 'signatory',
                    isMasqueraded: response.data.user_masqueraded && response.data.user_masqueraded,
                    subscription: response.data.subscription && response.data.subscription,
                    hasCcr: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.credit_history_enabled : false,
                    displayCcrBanner: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.credit_history_ready_banner_display : false,
                    hasCompanyHealth: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.company_health_enabled : false,
                    displayWelcomeScreen: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.display_welcome_screen : true,
                    hasAnalytics: response.data.companies && response.data.companies[0].products_config && response.data.plaid_items && response.data.plaid_items.length > 0 ? response.data.companies[0].products_config.analytics_enabled : false,
                    hasFinance: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.finance_enabled : false,
                    hasCashflow: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.cashflow_enabled : false,
                    hasRequestedCcr: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.credit_history_requested : false,
                    cashflow2WeeksEnabled: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.cashflow_created_at : null,
                    showTooltip: response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.show_cashflow_tooltip : false,
                    journeyOnboarding: response.data.onboarding_journey && response.data.onboarding_journey,
                    firstJourneyCompleted: response.data.first_journey_completed && response.data.first_journey_completed
                };

                return {
                    success: true,
                    email: response.data.email,
                    server: apiUrl
                };
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const updateUserDetails = async (apiUrl, token, userToken, userId, data) => {
        const options = {
            url: `${apiUrl}/api/v1/users/${userId}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                user: {
                    first_name: data.firstName,
                    last_name: data.lastName,
                    phone_number: data.phone
                },
                lead: { company_name: data.tradingName }
            }
        };


        try {
            const response = await CapacitorHttp.patch(options);
            if (response.status === 200) {

                return {
                    success: true,
                    email: response.data.email,
                    server: apiUrl
                };
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const setOnboardingComplete = async (apiUrl, token, userToken, companyId) => {
        const options = {
            url: `${apiUrl}/api/v1/company/products_config/onboarding_completed?company_id=${companyId}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };


        try {
            const response = await CapacitorHttp.patch(options);
            if (response.status === 200) {
                userStore.userObj.onBoardingComplete = response.data.companies && response.data.companies[0].products_config ? response.data.companies[0].products_config.onboarding_complete : false;

                return {
                    success: true
                };
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const refreshCompanyCroData = async (apiUrl, token, userToken, companyId) => {
        const options = {
            url: `${apiUrl}/api/v1/analytics_data/companies/${companyId}/trigger_cro_refresh`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };


        try {
            const response = await CapacitorHttp.patch(options);
            if (response.status === 200) {
                return {
                    success: true
                };
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const selectProfile = async (apiUrl, token, userToken, data) => {
        const options = {
            url: `${apiUrl}/api/v1/customer/campaign_leads`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                campaign_lead: {
                    product_type: data.journey
                },
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.error) {
                return {
                    success: false,
                    error: response.error
                }
            } else {
                return {
                    success: true
                }
            }
        } catch (err) {
            return err;
        }
    };

    const getCompanyName = async (apiUrl, token, userToken) => {
        const options = {
            url: `${apiUrl}/api/v1/customer/campaign_leads/company_name`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return [err, response.status];
        }
    };

    const searchCompany = async (apiUrl, token, tradingName, type) => {
        const options = {
            url: apiUrl + `/cro/company_listings/companies?term=${tradingName}&business_indicator=${type}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            return [response.data, response.status];
        } catch (err) {
            return [err, response.status];
        }
    };

    const getTradingProfileProps = async (apiUrl, token, userToken) => {
        const options = {
            url: apiUrl + `/api/v1/business/data_selects`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return [err, response.status];
        }
    };

    const getExistingTradingProfileData = async (apiUrl, token, userToken, companyId) => {
        const options = {
            url: apiUrl + `/api/v1/companies/${companyId}/trading_profile`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 404) {
                return {
                    success: false,
                    error: "404 Not Found"
                }
            }
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return [err, response.status];
        }
    };

    const getExistingApplicationData = async (apiUrl, token, userToken, companyId, projectId) => {
        const options = {
            url: apiUrl + `/api/v1/customer/ca/applications/${projectId}?company_id=${companyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return [err, response.status];
        }
    };


    const getPlaidToken = async (apiUrl, token, userToken, companyId, device) => {
        const options = {
            url: apiUrl + `/api/v1/company/plaid_accounts/new?company_id=${companyId}&source=${device}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return response.data;
            }
        } catch (err) {
            return [err, response.status];
        }
    };

    const createCompany = async (apiUrl, token, userToken, data) => {
        let companyData = {};

        // CRO sends us business_name that corresponds to limited company legal name, and business name' trading name
        let legalName = data.companyType === 'partnership' ? data.partnershipNames : data.legalName
        let tradingName = data.companyType in ['partnership', 'sole_trader'] ? data.legalName : data.tradingName
        if (data && data.croNumber) {
            companyData = {
                legal_name: legalName,
                trading_name: data.tradingName,
                cro_number: data.croNumber && data.croNumber,
                company_type: data.companyType,
                business_indicator: data.businessIndicator && data.businessIndicator,
                compact_full_address_line: data.address,
                date_registered: data.dateRegistered,
                year_established: data.year,
                analytics_enabled: data.companyProducts && data.companyProducts.analytics_enabled,
                finance_enabled: data.companyProducts && data.companyProducts.finance_enabled
            };
        } else {
            companyData = {
                legal_name: legalName,
                trading_name: data.tradingName,
                company_type: data.companyType,
                compact_full_address_line: data.address,
                year_established: data.year,
                analytics_enabled: data.companyProducts && data.companyProducts.analytics_enabled,
                finance_enabled: data.companyProducts && data.companyProducts.finance_enabled
            };
        }

        const options = {
            url: `${apiUrl}/api/v1/companies`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken
            },
            data: companyData
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `The ${data.legalName} company was successfully created`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            console.log('err: ', err);
            return err;
        }
    };

    const updateCompany = async (apiUrl, token, userToken, companyId, data, profileComplete) => {
        let companyData = {};

        // CRO sends us business_name that corresponds to limited company legal name, and business name' trading name
        let legalName = data.companyType === 'partnership' ? data.partnershipNames : data.legalName
        let tradingName = data.companyType in ['partnership', 'sole_trader'] ? data.legalName : data.tradingName
        if (data && data.croNumber) {
            companyData = {
                legal_name: legalName,
                trading_name: data.tradingName,
                cro_number: data.croNumber && data.croNumber,
                company_type: data.companyType,
                business_indicator: data.businessIndicator && data.businessIndicator,
                compact_full_address_line: data.address,
                date_registered: data.dateRegistered,
                year_established: data.year,
                analytics_enabled: data.companyProducts && data.companyProducts.analytics_enabled,
                finance_enabled: data.companyProducts && data.companyProducts.finance_enabled
            };
        } else {
            companyData = {
                legal_name: legalName,
                trading_name: data.tradingName,
                company_type: data.companyType,
                compact_full_address_line: data.address,
                year_established: data.year,
                analytics_enabled: data.companyProducts && data.companyProducts.analytics_enabled,
                finance_enabled: data.companyProducts && data.companyProducts.finance_enabled
            };
        }

        const options = {
            url: `${apiUrl}/api/v1/companies/${companyId}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken
            },
            data: !profileComplete && companyData
        };


        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `The ${data.legalName} company was successfully updated`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const enableFinanceOrAnalytics = async (apiUrl, token, userToken, data, productType) => {
        let companyData = {};

        if (data && data.cro_number && productType === 'analytics') {
            companyData = {
                company: {
                    cro_number: data.cro_number && data.cro_number,
                    analytics_enabled: true
                }
            };
        }
        if (data && data.croNumber && productType === 'finance') {
            companyData = {
                company: {
                    cro_number: data.cro_number && data.cro_number,
                    finance_enabled: true
                }
            };
        }
        const options = {
            url: `${apiUrl}/api/v1/companies/${data.id}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken
            },
            data: companyData
        };


        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `The ${data.legal_name} company was successfully updated`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const createTradingProfile = async (apiUrl, token, userToken, data, companyId) => {
        const options = {
            url: `${apiUrl}/api/v1/company/trading_profiles`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken
            },
            data: {
                company_id: companyId,
                trading_profile: {
                    website: data.business_website,
                    annual_turnover: data.annual_turnover,
                    county_id: data.trading_locations,
                    company_size: data.company_size,
                    business_sectors: data.business_sector,
                    merchant_ids: data.merchant_provider
                }
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `The ${data.companyId} company was successfully created`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const taxStatusInfo = async (apiUrl, token, userToken, companyId) => {
        const options = {
            url: apiUrl + `/api/v1/company/trading_profiles/tax_status?company_id=${companyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const submitTaxDetails = async (apiUrl, token, userToken, data, companyId) => {
        const options = {
            url: `${apiUrl}/api/v1/company/trading_profiles`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken
            },
            data: {
                company_id: companyId,
                trading_profile: {
                    monthly_revenue_arrangement_amount: data.monthly_revenue_arrangement_amount && data.monthly_revenue_arrangement_amount,
                    tax_position_status: data.tax_position_status && data.tax_position_status,
                    estimated_duration_months_left: data.estimated_duration_months_left && data.estimated_duration_months_left,
                    further_information: data.further_information && data.further_information
                }
            }
        };


        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `The tax details for the company ${data.companyId} was successfully updated`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const exchangePublicToken = async (apiUrl, token, userToken, data) => {
        const options = {
            url: `${apiUrl}/api/v1/company/plaid_accounts/exchange_public_token`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                company_id: data.companyId,
                public_token: data.publicToken,
                metadata: {
                    institution: {
                        institution_id: data.institutionId,
                        name: data.institutionName
                    }
                }
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true,
                    message: `Plaid token exchange successful`
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const connectAccount = async (apiUrl, token, data) => {
        const options = {
            url: `${apiUrl}/api/v1/users` + navStore.utmTagsToString(),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                user: {
                    email: data.email.toLowerCase(),
                    password: data.password,
                    phone_number: data.phone,
                    accepted_tcs: 1
                },
                lead: { company_name: data.company }
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.error) {
                return {
                    success: false,
                    error: response.error
                }
            } else {
                return {
                    success: true,
                    email: email
                }
            }
        } catch (err) {
            return err;
        }
    };

    const createLoanProject = async (apiUrl, token, userToken, data, companyId) => {
        const options = {
            url: `${apiUrl}/api/v1/customer/ca/applications?company_id=${companyId}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken
            },
            data: {
                project: {
                    amount: data.amount,
                    preferred_term_months: data.preferred_term_months,
                    description: data.description,
                    average_non_card_takings: data.average_non_card_takings,
                    average_card_takings: data.average_card_takings,
                }
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `The ${data.companyId} successfully created loan`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const getSupportingDocsProps = async (apiUrl, token, userToken, applicationId, companyId) => {
        const options = {
            url: apiUrl + `/api/v1/customer/ca/applications/${applicationId}/application_documents?company_id=${companyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return [err, response.status];
        }
    };

    const getTaxDocsProps = async (apiUrl, token, userToken, applicationId, companyId) => {
        const options = {
            url: apiUrl + `/api/v1/customer/ca/applications/${applicationId}/application_documents/tax_clearance_certificate?company_id=${companyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return [err, response.status];
        }
    };

    const updateLoanDocs = async (apiUrl, token, userToken, data, applicationId, companyId) => {
        console.log("data: ", data);
        let formData = new FormData();
        data.forEach((item) => {
            if (item.files && item.files.length > 0) {
                item.files.forEach((file, i) => {
                    if (file.file_name) {
                        console.log("file existing");
                    } else {
                        formData.append([item.id.toString(), i.toString()], file, file.name);
                    }
                });
            }
        });

        console.log("formData: ", formData);

        const options = {
            url: `${apiUrl}/api/v1/customer/ca/applications/${applicationId}/application_documents/update_all?company_id=${companyId}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
                Token: userToken
            },
            data: formData
        };


        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `The ${data.companyId} successfully created loan`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return {
                success: false,
                error: err
            }
        }
    };

    const updateLoanDocsIos = async (apiUrl, token, userToken, data, applicationId, companyId) => {
        let formData = new FormData();
        data.forEach((item) => {
            if (item.files && item.files.length > 0) {
                item.files.forEach((file, i) => {
                    formData.append([item.id.toString(), i.toString()], file, file.name);
                });
            }
        });

        const bodyData = {};
        for (const [key, val] of Object.entries(formData)) {
            bodyData[key] = val;
        }
        const options = {
            url: `${apiUrl}/api/v1/customer/ca/applications/${applicationId}/application_documents/update_all?company_id=${companyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: bodyData,
        };

        try {
            const response = await CapacitorHttp.patch(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `The ${data.companyId} successfully created loan`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return {
                success: false,
                error: err
            }
        }
    };

    const submitLoanDeclaration = async (apiUrl, token, userToken, data, applicationId, companyId) => {
        const options = {
            url: `${apiUrl}/api/v1/customer/ca/applications/${applicationId}/application_declarations?company_id=${companyId}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken
            },
            data: {
                application_declaration: {
                    rental_arrears_presently_or_expiring_in_12_months: data.rental_arrears_presently_or_expiring_in_12_months,
                    current_account_overdraft_limit: data.current_account_overdraft_limit,
                    monthly_rent_amount: data.monthly_rent_amount,
                    comments: data.comments,
                }
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data,
                    message: `The ${data.companyId} successfully created loan`
                }
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const financeSubmitApplication = async (apiUrl, token, userToken, applicationId, companyId) => {
        const options = {
            url: `${apiUrl}/api/v1/customer/ca/applications/${applicationId}/submit_application?company_id=${companyId}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                id: applicationId
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true,
                    data: response.data

                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const partnerValidateToken = async (apiUrl, token, validateToken) => {
        const options = {
            url: `${apiUrl}/api/v1/link_accesses/validate_token`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: {
                link_access: {
                    token: validateToken
                }
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.data.error) {
                return {
                    success: false,
                    error: response.data.error
                }
            } else {
                return {
                    success: true,
                    data: response.data
                }
            }
        } catch (err) {
            return err;
        }
    };

    const submitEmailData = async (apiUrl, token, userToken, email, name, path) => {
        const options = {
            url: `${apiUrl}/api/v1/${path}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                email: email,
                name: name
            }
        };


        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return {
                    success: true,
                }
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    return {
        startOnboardingState,
        createOnboardingState,
        updateOnboardingState2,
        updateOnboardingState,
        createAccount,
        selectProfile,
        searchCompany,
        createCompany,
        updateCompany,
        enableFinanceOrAnalytics,
        createTradingProfile,
        connectAccount,
        confirmCompany,
        getCompanyName,
        getPlaidToken,
        getTradingProfileProps,
        getExistingTradingProfileData,
        getExistingApplicationData,
        exchangePublicToken,
        updateUserDetails,
        setOnboardingComplete,
        refreshCompanyCroData,
        createLoanProject,
        getSupportingDocsProps,
        getTaxDocsProps,
        updateLoanDocs,
        updateLoanDocsIos,
        submitLoanDeclaration,
        financeSubmitApplication,
        partnerValidateToken,
        startOnboardingState2,
        submitEmailData,
        updateDisplayWelcomeScreen,
        getSignatoryProfiles,
        createSignatoryProfile,
        updateSignatoryProfile,
        taxStatusInfo,
        submitTaxDetails
    }
}
