<template>
    <ion-menu side="end" id="filter-side-menu" menu-id="filter-side-menu" :content-id="contentId" type="overlay">
        <ion-content>
        <div class="flex flex-col h-full">
          <div v-show="userStore.userObj.loggedInUser && userStore.userObj.activeCompany" class="flex xl:hidden flex-row pr-3">
            <grid-select-account :isAccount="true" :hasImg="true" :activeAccount="userStore.userObj.activeAccount && userStore.userObj.activeAccount" :accounts="userStore.userObj.accounts && userStore.userObj.accounts"></grid-select-account>
            <ChevronDownIcon class="w-4 stroke-[3px]"/>
          </div>
          <div v-show="pathsWithCustomFilters.includes(route.path)" class="flex flex-col p-4">
            <div class="flex flex-col justify-start items-start w-full">
              <div
                class="ml-2 flex flex-col justify-start items-start relative gap-1 w-full"
              >
                <div class="flex justify-start items-center mb-4 pr-4 w-full">
                  <grid-input v-model="startDate" type="date" label="Pick start date" placeholderText="12/01/2015" customClass="flex-row-reverse text-secondary-300" labelClass="text-[15px] font-semibold text-left text-neutral-500 pb-1"></grid-input>
                </div>
                <div class="flex justify-start items-center pr-4 w-full">
                  <grid-input v-model="endDate" type="date" label="Pick end date" placeholderText="12/31/2015" customClass="flex-row-reverse text-secondary-300" labelClass="text-[15px] font-semibold text-left text-neutral-500 pb-1"></grid-input>
                </div>
              </div>
            </div>
            <ion-list 
              id="inbox-list" 
              class="relative overflow-hidden bg-transparent">

            </ion-list>
            <ion-menu-toggle
                  auto-hide="false"
              >
             <div v-show="userStore.userObj.loggedInUser" class="p-2">
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0 mb-4">
                  <grid-button @click="applyFilters" variant="primary" label="Save Filters" size="full"></grid-button>
                </div>
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0">
                  <grid-button @click="clearFilters" variant="outline" label="Clear Filters" size="full"></grid-button>
                </div>
              </div>
              <div v-show="!userStore.userObj.loggedInUser" class="p-2">
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0">
                  <grid-button @click="applyFilters" variant="outline" label="Apply Filters" size="full"></grid-button>
                </div>
              </div>
              <div v-show="!userStore.userObj.loggedInUser" class="p-2">
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0">
                  <grid-button @click="clearFilters" variant="primary" label="Clear Filters" size="full"></grid-button>
                </div>
              </div>
            </ion-menu-toggle>
          </div>
          <div
              class="hidden justify-start items-center w-[304px] relative gap-4 pr-[18px] pt-2 pb-8 rounded-lg"
            >
              <ProfileIcon />
              <div class="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 relative">
                <p class="flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-[#6d738c]">
                  {{ repaymentsStore.name }}
                </p>
                <p class="flex-grow-0 flex-shrink-0 text-base text-left text-[#6d738c]">{{ repaymentsStore.company }}</p>
              </div>
          </div>
        </div>

        </ion-content>
    </ion-menu>
</template>

<script>
import {
  IonMenu,
  IonContent,
  IonItem,
  IonList,
  IonMenuToggle,
  menuController
} from "@ionic/vue";
import { ChevronDownIcon } from '@heroicons/vue/outline'
import { defineComponent, ref, inject, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import GridLogoStdText from "@/components/icons/GridLogoStdText";
import GridIonAccordion from "@/components/ui/GridIonAccordion";
import GridInput from "@/components/ui/GridInput";
import {
        TemplateIcon,
        DocumentTextIcon, 
        DatabaseIcon,
        CreditCardIcon, 
        BookmarkIcon, 
        ChatAlt2Icon,
        UserCircleIcon,
        OfficeBuildingIcon,
        LogoutIcon
} from '@heroicons/vue/outline'
import ProfileIcon from '@/components/icons/ProfileIcon';
import { useRepaymentsStore } from "@/stores/repayments";
import { useUserStore } from "@/stores/user";
import { useNavStore } from "@/stores/nav";
import GridButton from "@/components/ui/GridButton";
import LoginModal from "@/components/ui/modals/LoginModal";
import useAuthApi from "@/apis/useAuthApi.js";
import { useFilterStore } from "@/stores/filter";
import GridSelect from "@/components/ui/GridSelect";
import GridSelectAccount from "@/components/ui/GridSelectAccount";

export default defineComponent({
  name: "SideBarFilter",
  components: {
    IonMenu,
    IonContent,
    IonList,
    IonMenuToggle,
    ProfileIcon,
    GridButton,
    GridInput,
    ChevronDownIcon,
    GridSelectAccount
  },
  props: {
    pages: {
        type: Array
    },
    labels: {
        type: Array
    },
    contentId: {
        type: String
    },
    menuId: {
        type: String
    },
  },
  setup(props) {
    const showFilterBar = ref(false);
    const startDate = ref('');
    const endDate = ref('');
    const emitter = inject('emitter');
    const selectedIndex = ref(0);
    const pagesWithSideMenu = ref(['/dashboard']);
    const router = useRouter()
    const route = useRoute();
    const path = window.location.pathname;
    const userStore = useUserStore();
    const navStore = useNavStore();
    const repaymentsStore = useRepaymentsStore();
    const filterStore = useFilterStore();
    const menuShow = ref(true);
    const pathsWithCustomFilters = ref([
      '/finance/repayments',
      '/finance/term_repayments',
      '/finance/merchant_transactions',
      '/finance/terminal_income',
      '/analytics/banking_analysis',
      '/analytics/transaction_analysis',
      '/analytics/categorised_transaction_analysis'
    ]);

    const toggleFilterBar = () => { 
      menuShow.value = !menuShow.value;
      menuController.enable(menuShow.value, 'filter-side-menu');
      menuController.open('filter-side-menu');
    }

    const applyFilters = () => { 
      filterStore.activeFilters.date_period = 'custom';
      filterStore.activeFilters.start_date = startDate.value;
      filterStore.activeFilters.end_date = endDate.value;
      emitter.emit('applyFilters', filterStore.activeFilters);
    }

    const clearFilters = () => { 
      filterStore.activeFilters.date_period && delete filterStore.activeFilters.date_period
      filterStore.activeFilters.start_date && delete filterStore.activeFilters.start_date
      filterStore.activeFilters.end_date && delete filterStore.activeFilters.end_date
      startDate.value = '';
      endDate.value = '';
      //emitter.emit('applyFilters', {});
      emitter.emit('clearFilters');
    }

    emitter.on('closeSideBarFilter', () => {
      toggleFilterBar();
    });

    emitter.on('clearSideBarFilters', (filters) => {
      clearFilters();
    });

    emitter.on('removeActiveSideBarFilters', (filters) => {
      startDate.value = '';
      endDate.value = '';
    });

    onMounted(() => {
      toggleFilterBar();
    });

    
    return {
      selectedIndex,
      TemplateIcon,
      DocumentTextIcon, 
      DatabaseIcon,
      CreditCardIcon, 
      BookmarkIcon, 
      ChatAlt2Icon,
      UserCircleIcon,
      OfficeBuildingIcon,
      LogoutIcon,
      repaymentsStore,
      navStore,
      userStore,
      route,
      pagesWithSideMenu,
      startDate,
      endDate,
      showFilterBar,
      pathsWithCustomFilters,
      applyFilters,
      clearFilters,
      toggleFilterBar,
      isSelected: (url) => (url === route.path ? "selected" : ""),
    };
  },
});
</script>

<style scoped>

.ios #sidenav-grid-logo {
  padding-top: 2rem !important;
}

ion-menu ion-content {
  --background: #F9FAFB;
}

ion-item {
  --background: transparent;
  --color: #6D738C;
}

div.item-native {
  background: transparent;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-accordion-group ion-accordion ion-list {
  padding: 0 !important;;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  /*border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);*/
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 8px;
}

ion-menu.md ion-item.selected {
  --background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
}

ion-menu.md ion-item:hover {
  --background: #F9FAFB;
  border: 1px solid #206BFA;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}
ion-item.selected {
  --color: #206BFA;
}
ion-accordion-group ion-accordion ion-item {
  --background: #F9FAFB;
}
ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --color: #206BFA;
}

</style>
