import { defineStore } from 'pinia'
import { ref, inject } from "vue";

export const useOnboardingStore = defineStore("onboarding", () => {

    const customerOnboardingObj = ref({
        title: "Finish your application customer!",
        text: "You have an application in drafts, click the banner to finish the application now.",
        data: {
            date: "Wed Aug 17 2022",
            link: "/partners",
        }
    });

    const states = ref({});

    const loanApplicationObj = ref({
        ...customerOnboardingObj.value
    });

    const customerOnboardingIndex = ref(0);

    const customerOnboardingCompanyId = ref(null);

    const customerOnboardingArr = ref(['CreateAccount', 'SelectProfile', 'SearchCompany', 'CreateCompany', 'CreateTradingProfile', 'ConnectAccount', 'ExistingUser', 'SelectPlan', 'CreateTradingProfileFinance', 'LoanAmount', 'SupportingDocs', 'LoanDeclarations', 'SubmitApplication', 'OnboardComplete']);

    const updateCustomerOnboardingIndex = async (index) => {
        customerOnboardingIndex.value = index;
        return index;
    }

    const updateCustomerOnboardingCompany = async (id) => {
        customerOnboardingCompanyId.value = id;
    }

    const updateCustomerOnboardingIndexByArr = async (pathStr) => {
        customerOnboardingArr.value.indexOf(pathStr) !== -1 && updateCustomerOnboardingIndex(customerOnboardingArr.value.indexOf(pathStr))
    }

    const updateActiveOnboardingStates = async (allOnboardings) => {
        states.value = await resolveOnboardingStates(allOnboardings);
    }

    const resolveOnboardingStates = async (allOnboardings) => {
        return new Promise((resolve, reject) => {
            resolve(allOnboardings)
        });
    }

    const getActiveOnboardingState = async (key) => {
        if (states.value) {
            if (states.value[key]) {
                return states.value[key];
            }
            else return null;
        }
    }

    const getActiveOnboardingIndex = async (key) => {
        return new Promise((resolve, reject) => {
            if (states.value) {
                if (states.value[key] && Array.isArray(states.value[key])) {
                    let completedState = null;
                    states.value[key].forEach((elem, i) => {
                        if (elem.state === 'completed') {
                            completedState = i;
                        }
                        if (elem.state !== 'completed') {
                            resolve(i);
                        }
                    });
                    if (completedState) resolve(completedState);
                    else resolve(null);
                }
                else {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        })
    }

    const isOnboardingComplete = async (key) => {
        return new Promise((resolve, reject) => {
            if (states.value) {
                if (states.value[key] && Array.isArray(states.value[key])) {
                    let completedState = null;
                    states.value[key].forEach((elem, i) => {
                        if (elem.state === 'completed') {
                            completedState = true;
                        }
                        if (elem.state !== 'completed') {
                            resolve(false);
                        }
                    });
                    if (completedState) resolve(completedState);
                    else resolve(false);
                }
                else if (states.value[key] && !Array.isArray(states.value[key]) && states.value[key].state === 'completed') {
                    resolve(true);
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        })
    }

    const onboardingRedirect = async (key) => {
        return new Promise((resolve, reject) => {
            if (states.value) {
                if (states.value[key] && states.value[key].step) {
                    resolve(states.value[key].step)
                } else {
                    resolve(null)
                }
            } else {
                resolve(null)
            }
        });
    }

    return { states, customerOnboardingObj, loanApplicationObj, customerOnboardingIndex, customerOnboardingCompanyId, updateCustomerOnboardingCompany, updateCustomerOnboardingIndex, updateCustomerOnboardingIndexByArr, updateActiveOnboardingStates, onboardingRedirect, isOnboardingComplete, getActiveOnboardingState, getActiveOnboardingIndex }
});