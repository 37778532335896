

import { CapacitorHttp } from '@capacitor/core';

export default () => {

    const getAllNotifications = async (apiUrl, token, userToken) => {

        const options = {
            url: apiUrl + `/api/v1/notifications`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };

        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return response;
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const getLatestNotifications = async (apiUrl, token, userToken, entriesPerPage, pageNumber) => {

        const options = {
            url: apiUrl + `/api/v1/notifications?per_page=${entriesPerPage}&page=${pageNumber}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };

        try {
            const response = await CapacitorHttp.get(options);

            if (response.status === 200) {
                return { notifications: response.data.notifications, total_pages: response.data.total_pages, total_entries: response.data.total_entries, total_not_read: response.data.total_not_read };
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const getLatestBannerNotification = async (apiUrl, token, userToken) => {

        const options = {
            url: apiUrl + `/api/v1/banners/current`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };

        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return response.banner ?
                    {
                        data: response.banner,
                        active: true
                    } :
                    { active: false };
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const markNotificationAsRead = async (apiUrl, token, userToken, notificationId) => {

        const options = {
            url: apiUrl + `/api/v1/banners/${notificationId}/mark_as_read`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            webFetchExtra: {
                credentials: "include",
            },
        };
        try {
            const response = await CapacitorHttp.post(options);
            if (response.status === 200) {
                return response
            }
        } catch (err) {
            return err;
        }
    }

    const markAllNotificationsAsRead = async (apiUrl, token, userToken) => {

        const options = {
            url: apiUrl + `/api/v1/notifications/mark_as_read`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            webFetchExtra: {
                credentials: "include",
            },
        };
        try {
            const response = await CapacitorHttp.post(options);
            if (response.status === 200) {
                return true
            }
        } catch (err) {
            return err;
        }
    }

    const updateFbTokenOnLogin = async (apiUrl, token, userToken, fbToken, fbSource) => {
        const options = {
            url: apiUrl + `/api/v1/users/update_firebase_token`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            webFetchExtra: {
                credentials: "include",
            },
            data: {
                user: {
                    source: fbSource,
                    firebase_token: fbToken
                }
            }
        };
        try {
            const response = await CapacitorHttp.post(options);

            if (response.status === 200) {
                return true;
            }
        } catch (err) {
            return err;
        }
    }

    return {
        getAllNotifications,
        getLatestNotifications,
        getLatestBannerNotification,
        markNotificationAsRead,
        markAllNotificationsAsRead,
        updateFbTokenOnLogin
    }
}