<template>
      <div
        class="w-full h-full flex flex-col justify-center items-start gap-2.5 px-8 md:px-16 py-8 rounded-[20px] bg-white z-[9]"
      >
        <div v-show="isCompany" class="w-full flex flex-col justify-center items-start gap-5">
          <div
            class="w-full h-full flex flex-col justify-center items-start relative gap-3"
          >
            <p
              class=" text-[15px] font-semibold text-left text-gray-500"
            >
              Your accounts
            </p>
            <div
              v-for="(account, index) in accounts" :key="index"
              :class="['w-full flex flex-col justify-start items-start pl-1.5 pr-4 py-1.5 rounded-[38px] cursor-pointer', account.id === activeAccount.id ? 'border-2 border-[#206bfa]' : 'border-2 border-[#ffffff]']"
              @click="selectAccount(account)"
            >
              <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0">
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2">
                    <ProfileInitialsIcon :initials="account.initials" :fillColor="account.color" />
                  <div
                    class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-0.5"
                  >
                    <p class="flex-grow-0 flex-shrink-0 text-lg font-bold text-left text-gray-900">
                      {{ account.legal_name }}
                    </p>
                    <p class="flex-grow-0 flex-shrink-0 text-sm text-left text-gray-900">
                      {{ account.trading_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
            <div
              @click="redirectToPath(route.path)"
              data-value="[current-account-btn]"
              class="flex justify-center items-center flex-grow relative gap-2.5 px-6 py-2.5 rounded-lg bg-[#206bfa] cursor-pointer"
            >
              <p 
                class="flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-gray-50"
                >
                Select this account
              </p>
            </div>
          </div>
        </div>
        <div v-show="isAccount" class="w-full flex flex-col justify-center items-start gap-5">
          <div
            class="w-full h-full flex flex-col justify-center items-start relative gap-3"
          >
            <p
              class=" text-[15px] font-semibold text-left text-gray-500"
            >
              Your accounts
            </p>
            <div
              v-for="(account, index) in accounts" :key="index"
              :class="['w-full flex flex-col justify-start items-start pl-1.5 pr-4 py-1.5 rounded-[38px] cursor-pointer', account.id === activeAccount.id ? 'border-2 border-[#206bfa]' : 'border-2 border-[#ffffff]']"
              @click="selectAccount(account)"
              data-value="[current-account-list]"
            >
              <div class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0">
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2">
                    <BankGenIcon />
                  <div
                    class="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 relative gap-0.5"
                  >
                    <p class="flex-grow-0 flex-shrink-0 text-lg font-bold text-left text-gray-900">
                      {{ account.legal_name }}
                    </p>
                    <p class="flex-grow-0 flex-shrink-0 text-sm text-left text-gray-900">
                      {{ account.company_type }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
            <div
              @click="redirectToPath(route.path)"
              data-value="[current-account-btn]"
              class="flex justify-center items-center flex-grow relative gap-2.5 px-6 py-2.5 rounded-lg bg-[#206bfa] cursor-pointer"
            >
              <p 
                class="flex-grow-0 flex-shrink-0 text-lg font-semibold text-left text-gray-50"
                >
                Select this account
              </p>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import GridButton from "@/components/ui/GridButton";
import GridInput from "@/components/ui/GridInput";
import useAuthApi from "@/apis/useAuthApi.js";
import { useUserStore } from "@/stores/user";
import { useNavStore } from "@/stores/nav";
import { useRoute, useRouter } from "vue-router";
import { modalController } from "@ionic/vue";
import ForgotPasswordModal from "@/components/ui/modals/ForgotPasswordModal";
import ProfileInitialsIcon from '@/components/icons/ProfileInitialsIcon';
import BankGenIcon from '@/components/icons/BankGenIcon';

export default defineComponent({
  name: "SelectModalAccount",
  components: { //GridButton, 
  //GridInput 
  ProfileInitialsIcon,
  BankGenIcon
  },
  props: {
    accounts: {
      type: Array,
      default: () => [], 
    },
    activeAccountObj: {
        type: Object
    },
    name: {
        type: String,
        default: () => "Amazing Pizza Co."
    },
    initials: {
        type: String,
        default: () => "AP"
    },
    id: {
        type: String,
        default: () => "1158"
    },
    fillColor: {
        type: String,
        default: () => "#FF00FF"
    },
    isCompany: {
      type: Boolean,
      default: () => false
    },
    isAccount: {
      type: Boolean,
      default: () => false
    },
  },
  setup(props) {
    console.log('isAccount: ', props.isAccount);
    const activeAccount = ref(props.activeAccountObj);

    const { getCurrentUser } = useAuthApi();
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const navStore = useNavStore();
    const email = ref("");
    const password = ref("");
    const warningText = ref(false);

    const selectAccount = (account) => {
      activeAccount.value = account;
    }

    const redirectToPath = async (path) => {
      userStore.updateActiveAccount(activeAccount.value);
      await getCurrentUser(navStore.apiUrl, navStore.authToken, userStore.userObj.token, userStore.userObj.id);
      await navStore.updateSideMenu(userStore.userObj);
      router.go();
    }

    return {
      route,
      navStore,
      userStore,
      email,
      password,
      warningText,
      activeAccount,
      selectAccount,
      redirectToPath,
    }
  }
});
</script>

<style scope>

@media only screen and (max-width: 768px) {
  #SelectModalAccount {
    padding: 1rem;
  }

}

#SelectModalAccount {
  --border-radius: 16px!important;
  --height: auto;
  --min-height: 20rem;
}

#SelectModalAccount .ion-page {
  display: contents;
}

ion-modal {
  --border-radius: 16px!important;
  --height: 33rem;
}

ion-checkbox {
  --border-color: #9DA2B3;
}

</style>