<template>
  <span class="grid-loader">
      <svg :class="['cube rotate-45', iconClass]" :width="width" :height="height" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
      <g class="block">
          <rect class="block-1 opacity-70" x=" 0" y=" 0" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-2 opacity-10" x="32" y=" 0" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-3 opacity-70" x="64" y=" 0" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-3b opacity-70" x="96" y=" 0" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-4 opacity-70" x=" 0" y="32" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-5 opacity-10" x="32" y="32" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-6 opacity-10" x="64" y="32" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-6b opacity-70" x="96" y="32" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-7 opacity-70" x=" 0" y="64" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-8 opacity-10" x="32" y="64" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-9 opacity-10" x="64" y="64" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-9b opacity-70" x="96" y="64" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-10 opacity-70" x=" 0" y="96" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-11 opacity-70" x="32" y="96" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-12 opacity-70" x="64" y="96" width="33" height="33" :fill="fillColor"></rect>
          <rect class="block-13 opacity-70" x="96" y="96" width="33" height="33" :fill="fillColor"></rect>
      </g>
      </svg>
  </span>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        width: {
            type: String,
            default: () => "32"
        },
        height: {
            type: String,
            default: () => "32"
        },
        fillColor: {
            type: String,
            default: () => "#206bfa"
        },
        iconClass: {
            type: String,
            default: () => ""
        },
    },
    setup(props) {

      return {}
    }

});
</script>

<style>
    .grid-loader svg.cube .block > *{
      animation: anim-cube-grid 1s ease-in-out infinite;
    }
    .grid-loader svg.cube .block-1{
      transform-origin:16px 16px;
      animation-delay: -1.7s;
    }
    .grid-loader svg.cube .block-2{
      transform-origin:48px 16px;
      animation-delay: -1.6s;
    }
    .grid-loader svg.cube .block-3{
      transform-origin:80px 16px;
      animation-delay: -0.5s;
    }
    .grid-loader svg.cube .block-3b{
      transform-origin:112px 16px;
      animation-delay: -0.5s;
    }
    .grid-loader svg.cube .block-4{
      transform-origin:16px 48px;
      animation-delay: -0.8s;
    }
    .grid-loader svg.cube .block-5{
      transform-origin:48px 48px;
      animation-delay: -0.7s;
    }
    .grid-loader svg.cube .block-6{
      transform-origin:80px 48px;
      animation-delay: -0.6s;
    }
    .grid-loader svg.cube .block-6b{
      transform-origin:112px 48px;
      animation-delay: -0.5s;
    }
    .grid-loader svg.cube .block-7{
      transform-origin:16px 80px;
      animation-delay: -0.9s;
    }
    .grid-loader svg.cube .block-8{
      transform-origin:48px 80px;
      animation-delay: -0.8s;
    }
    .grid-loader svg.cube .block-9{
      transform-origin:80px 80px;
      animation-delay: -0.7s;
    }
    .grid-loader svg.cube .block-9b{
      transform-origin:112px 80px;
      animation-delay: -0.7s;
    }
    .grid-loader svg.cube .block-10{
      transform-origin:16px 112px;
      animation-delay: -0.7s;
    }
    .grid-loader svg.cube .block-11{
      transform-origin:48px 112px;
      animation-delay: -0.9s;
    }
    .grid-loader svg.cube .block-12{
      transform-origin:80px 112px;
      animation-delay: -0.8s;
    }
    .grid-loader svg.cube .block-13{
      transform-origin:112px 112px;
      animation-delay: -0.7s;
    }

    @keyframes anim-cube-grid{
      0%   {transform:scale(1.0);}
      25%  {transform:scale(0.0);}
      50%  {transform:scale(1.0);}
      100% {transform:scale(1.0);}
    }
  </style>