<template>
    <div class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2.5">
        <svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg" :class="iconClass" preserveAspectRatio="xMidYMid meet">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70186 1.40388C7.88954 1.31004 8.11046 1.31004 8.29814 1.40388L13.6315 4.07054C13.8573 4.18347 14 4.41431 14 4.66683V11.3335C14 11.586 13.8573 11.8169 13.6315 11.9298L8.29814 14.5964C8.11046 14.6903 7.88954 14.6903 7.70186 14.5964L2.36852 11.9298C2.14267 11.8169 2 11.586 2 11.3335V4.66683C2 4.41431 2.14267 4.18347 2.36852 4.07054L7.70186 1.40388ZM3.33333 5.74552V10.9215L7.33333 12.9215V7.74552L3.33333 5.74552ZM8.66667 7.74552V12.9215L12.6667 10.9215V5.74552L8.66667 7.74552ZM11.8426 4.66683L8 6.58814L4.15738 4.66683L8 2.74552L11.8426 4.66683Z" :fill="fillColor"></path>
        </svg>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        width: {
            type: String,
            default: () => "16"
        },
        height: {
            type: String,
            default: () => "16"
        },
        fillColor: {
            type: String,
            default: () => "#F9FAFB"
        },
        iconClass: {
            type: String,
            default: () => "flex-grow-0 flex-shrink-0 w-4 h-4 relative"
        },
    }

});
</script>