<template>
    <a
        :href="externalUrl && externalUrl"
    >
        <svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg" :class="iconClass" :style="gridStyle">
                <path
                    d="M34.6501 17.3207L31.0152 20.9544L17.3261 34.6392L3.63486 20.9544L0 17.3207L17.3261 0L21.9964 4.67097L20.4543 6.21254L18.3637 8.30468L17.3261 9.34194L9.34488 17.3207L12.9797 20.9544L13.3873 21.3596L17.3261 25.2972L21.6703 20.9544L25.3052 17.3207L21.3663 13.3831L26.0388 8.7121L34.6501 17.3207Z"
                    :fill="iconColor"
                ></path>
                <path
                    d="M34.6501 24.6157L17.3261 42.0002L0 24.6157L2.0928 22.5156L17.3261 37.8L32.5573 22.5156L34.6501 24.6157Z"
                    :fill="iconColorAlt"
                ></path>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M66.1268 20.2857V21.7692C66.1268 24.5165 65.2311 26.7417 63.4395 28.4451C61.648 30.1484 59.3339 31 56.4973 31C53.4741 31 50.9687 30.0339 48.9812 28.1017C46.9937 26.1694 46 23.8114 46 21.0275C46 18.2253 46.9844 15.8535 48.9532 13.9121C50.9221 11.9707 53.3621 11 56.2734 11C58.1022 11 59.7724 11.4075 61.2841 12.2225C62.7957 13.0376 63.9714 14.1227 64.8112 15.478L61.508 17.3462C61.0415 16.5586 60.337 15.9176 59.3946 15.4231C58.4521 14.9286 57.4024 14.6813 56.2454 14.6813C54.3792 14.6813 52.8442 15.2811 51.6405 16.4808C50.4369 17.6804 49.835 19.196 49.835 21.0275C49.835 22.8407 50.4462 24.3379 51.6685 25.5192C52.8909 26.7006 54.5098 27.2912 56.5253 27.2912C58.0182 27.2912 59.2546 26.9661 60.2343 26.3159C61.2141 25.6657 61.8812 24.7729 62.2358 23.6374H56.3013V20.2857H66.1268ZM80.0276 30.6154L76.0806 23.9396H73.1413V30.6154H69.2783V11.3846H77.1163C78.9265 11.3846 80.4661 12.0073 81.7351 13.2527C83.0041 14.4982 83.6386 16 83.6386 17.7582C83.6386 18.9487 83.2934 20.0522 82.6029 21.0687C81.9124 22.0852 80.998 22.8498 79.8596 23.3626L84.1985 30.6154H80.0276ZM73.1413 14.9286V20.6154H77.1163C77.8441 20.6154 78.4693 20.3361 78.9918 19.7775C79.5144 19.2189 79.7756 18.5458 79.7756 17.7582C79.7756 16.9707 79.5144 16.3022 78.9918 15.7527C78.4693 15.2033 77.8441 14.9286 77.1163 14.9286H73.1413ZM86.8741 11.3846H90.7371V30.6154H86.8741V11.3846ZM102.566 11.3846C105.254 11.3846 107.498 12.3095 109.299 14.1593C111.1 16.0092 112 18.2894 112 21C112 23.7106 111.1 25.9908 109.299 27.8407C107.498 29.6905 105.254 30.6154 102.566 30.6154H94.7844V11.3846H102.566ZM102.566 26.989C104.265 26.989 105.646 26.435 106.709 25.3269C107.773 24.2189 108.305 22.7766 108.305 21C108.305 19.2234 107.773 17.7811 106.709 16.6731C105.646 15.565 104.265 15.011 102.566 15.011H98.6474V26.989H102.566Z"
                    :fill="iconColor"
                ></path>
        </svg>
    </a>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        externalUrl: {
            type: String
        },
        width: {
            type: String,
            default: () => "112"
        },
        height: {
            type: String,
            default: () => "42"
        },
        iconColor: {
            type: String,
            default: () => "#0B1640"
        },
        iconColorAlt: {
            type: String,
            default: () => "#206BFA"
        },
        gridStyle: {
            type: String
        },
        iconClass: {
            type: String,
            default: () => "flex-grow-0 flex-shrink-0 w-28 h-[42px]"
        },
    }

});
</script>