<template>
    <component v-if="isLoaded" :is="navIcon" :class="baseClass" :style="iconStyle" />
</template>

<script>

import { shallowRef, onMounted } from 'vue'
import * as heroIcons from '@heroicons/vue/outline';

export default {
    components: {
        heroIcons
    },
    props: {
        name: {
            type: String,
            default: () => "HomeIcon"
        },
        baseClass: {
            type: String
        },
        iconStyle: {
            type: String
        }
    },
    setup(props) {
        const isLoaded = shallowRef(false);
        const navIcon = heroIcons[props.name];

        onMounted(() => {
            isLoaded.value = true;
        })

        
        return { isLoaded, navIcon };
    },
};
</script>

<style>



</style>