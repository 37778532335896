import axios from 'axios'
import { defineStore } from 'pinia'

export const useRepaymentsStore = defineStore('repayments', {
    state: () => ({
        repayments: [],
        name: '',
        company: '',
        loading: false,
        currentUser: {}
    }),
    actions: {
        addRepayment(value) {
            this.repayments.push(value)
        },
        deleteRepayment(value) {
            this.repayments.splice(value, 1)
        },
        parseDate(data) {
            return data.map((elem) => {
                var d = new Date(elem.date);
                return {
                    months: d.toLocaleString("en-us", { month: "short" }),
                    amount: elem.amount,
                };
            });
        },
        async getRepayments() {
            this.loading = true
            const response = await axios.get('https://testing-heroku-20.herokuapp.com/api_vue/v1/chart_test.json')
            try {
                this.repayments = this.parseDate(await response.data.repayments);
                this.loading = false
            } catch (err) {
                this.repayments = [];
                console.error('Error loading new Repayments:', err);
                return err;
            }
        },
        getYearMonthIncomeSum(dataArr) {
            const mapper = single => {
                let d = single.week_end_dte.split('-');
                let t_income = Number(single.total_income);
                return { date: d[0] + '-' + d[1], amount: t_income };
            }
            const reducer = (group, current) => {
                let i = group.findIndex(single => (single.date == current.date));
                if (i == -1) {
                    return [...group, current];
                }
                group[i].amount += current.amount;
                return group;
            };
            const sumPrices = dataArr.map(mapper).reduce(reducer, []);
            return sumPrices;
        },
        getYearMonthRollingAvgSum(dataArr) {
            const mapper = single => {
                let d = single.week_end_dte.split('-');
                let t_rolling_4_week_average = Number(single.rolling_4_week_average);
                return { date: d[0] + '-' + d[1], amount: t_rolling_4_week_average };
            }
            const reducer = (group, current) => {
                let i = group.findIndex(single => (single.date == current.date));
                if (i == -1) {
                    return [...group, current];
                }
                group[i].amount += current.amount;
                return group;
            };
            const sumPrices = dataArr.map(mapper).reduce(reducer, []);
            return sumPrices;
        },
        async getUserDetails(apiUrl, token) {
            const response = await fetch(apiUrl + '/api_vue/v1/chart_test.json', {
                headers: {
                    'Content-Type': 'application/json',
                    method: 'GET',
                    Authorization: `Bearer ${token}`,
                },
                data: {}
            });
            try {
                this.name = await response.data.user.full_name;
                this.company = await response.data.company;
                this.loading = false
            } catch (err) {
                this.name = '';
                this.company = '';
                console.error('Error loading new Repayments:', err);
                return err;
            }
        },
        async getSampleAnalyticsData(apiUrl, token) {
            const response = await fetch(apiUrl + `/api/v1/analytics_data/samples/3`, {
                headers: {
                    'Content-Type': 'application/json',
                    method: 'GET',
                    Authorization: `Bearer ${token}`,
                }
            });
            try {
                const data = await response.json();
                if (response.status === 200) {
                    return data;
                }
            } catch (err) {
                return [err, response.status];
            }
        },
        async getUser() {
            const response = await fetch('https://testingb1.gridfinance.ie/api/v1/users/6999', {
                headers: {
                    'Content-Type': 'application/json',
                    method: 'GET',
                    Authorization: 'Bearer d4f35b362dd68a08e3a20ac363be9259',
                },
                data: {}
            });
            try {
                this.currentUser = await response.json();
                console.log(this.currentUser)
            } catch (err) {
                console.error('Error loading user data:', err);
                return err;
            }
        },
        async createUser(email, password, phone, company) {
            const response = await fetch('https://testingb1.gridfinance.ie/api/v1/users/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer c9c93e38fb286e2a11d38e12cfd2de52',
                },
                body: JSON.stringify({
                    user: {
                        email: email,
                        password: password,
                        phone_number: phone,
                        accepted_tcs: 1
                    },
                    lead: { company_name: company }
                })
            });


            try {
                const data = await response.json();
                console.log('data');
                console.log(data);
            } catch (err) {
                console.error('Error loading user data:', err);
                return err;
            }
        }
    },
    getters: {
        filterRepayments: (state) => {
            return (value) => state.repayments.filter((repayment) => repayment.title.includes(value))
        },
    }
})