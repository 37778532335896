<template>
      <div
        class="w-full h-full flex flex-col justify-center items-start gap-2.5 px-8 md:px-16 py-8 rounded-[20px] bg-white z-[9]"
      >
        <div class="w-full flex flex-col justify-center items-start gap-5">
          <div
            class="w-full h-full flex flex-col justify-center items-start relative gap-3"
          >
            <h1
              class=" text-3xl font-semibold text-left text-gray-500"
            >
              {{ title }}
            </h1>
            <p>{{ description }}</p>
          </div>
          <div class="flex flex-row">
            <img :src="imgUrl" />
          </div>
        </div>
      </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import GridButton from "@/components/ui/GridButton";
import GridInput from "@/components/ui/GridInput";
import useAuthApi from "@/apis/useAuthApi.js";
import { useUserStore } from "@/stores/user";
import { useNavStore } from "@/stores/nav";
import { useRoute, useRouter } from "vue-router";
import { modalController } from "@ionic/vue";
import ForgotPasswordModal from "@/components/ui/modals/ForgotPasswordModal";
import ProfileInitialsIcon from '@/components/icons/ProfileInitialsIcon';

export default defineComponent({
  name: "NotificationModal3",
  components: { //GridButton, 
  //GridInput 

  },
  props: {
    title: {
        type: String
    },
    description: {
        type: String
    },
    imgUrl: {
        type: String
    },
  },
  setup(props) {

    const { loginUser } = useAuthApi();
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const navStore = useNavStore();
    const email = ref("");
    const password = ref("");
    const warningText = ref(false);


    return {
      route,
      navStore,
      userStore,
      email,
      password,
      warningText,
    }
  }
});
</script>

<style scope>

@media only screen and (max-width: 768px) {
  #NotificationModal3 {
    padding: 1rem;
  }

}

#NotificationModal3 {
  --border-radius: 16px!important;
  --height: auto;
  --min-height: 20rem;
}

#NotificationModal .ion-page {
  display: contents;
}

ion-modal {
  --border-radius: 16px!important;
  --height: 33rem;
}

ion-checkbox {
  --border-color: #9DA2B3;
}

</style>