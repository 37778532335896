<template>
  <div :class="[baseClass, customClass, topBarPosition]">
    <div @click="bannerRedirect(link)" :class="subClass">
      <div :class="['flex flex-row gap-3', link && link.length > 0 ? 'cursor-pointer' : 'cursor-default']">
        <p :class="titleClass">
          {{ title }}
        </p>
        <a v-if="externalUrl" :href="externalUrl" target="_blank" :class="['cursor-pointer']">
          <div :class="labelClass" v-html="text"/>
        </a>
        <div v-else :class="labelClass" v-html="text"/>
      </div>
      <div class="flex gap-8 pl-8">
        <p class="text-[12px] text-white/60 whitespace-nowrap">
          {{ date }}
        </p>
      </div>
    </div>
    <button v-if="id && id.length > 0" :class="closeClass" @click="closeBanner(id)">
      <div class="flex flex-row gap-4">
        <p class="text-[12px] pt-[.2rem]">Fri Dec 1 2023</p>
        <XIcon data-value="[close-btn]" class="w-5 stroke-[3px] text-gray-300"/>
      </div>
    </button>
    <button v-else :class="closeClass" @click="closeNotification">
      <XIcon data-value="[close-btn]" class="w-5 stroke-[3px] text-gray-300"/>
    </button>
  </div>
</template>

<script>
  import { defineComponent, onMounted, ref, inject } from "vue";
  import { useRouter, useRoute } from "vue-router";
  import { useNavStore } from "@/stores/nav";
  import {
          XIcon
  } from '@heroicons/vue/outline';
  import { useUserStore } from "@/stores/user";
  import useNotificationsApi from "@/apis/useNotificationsApi.js";

  export default defineComponent({
    name: "TopBannerNotification",
    components: {
      XIcon
    },
    props: {
      id: {
          type: String,
          default: () => ""
      },
      title: {
          type: String,
          default: () => ""
      },
      text: {
          type: String,
          default: () => ""
      },
      date: {
          type: String,
          default: () => ""
      },
      link: {
          type: String,
          default: () => ""
      },
      externalUrl: {
          type: String
      },
      baseClass: {
          type: String,
          default: () => "flex flex-row justify-center items-center w-full bg-neutral-900 text-white absolute top-[4.5rem]"
      },
      closeClass: {
          type: String,
          default: () => ""
      },
      subClass: {
          type: String,
          default: () => "flex flex-row justify-between items-center h-full w-4/5 p-6 bg-neutral-900 text-white"
      },
      customClass: {
          type: String
      },
      labelClass: {
          type: String,
          default: () => "text-[15px]"
      },
      titleClass: {
          type: String,
          default: () => "font-bold text-[17px] whitespace-nowrap"
      },
      closeNotification: {
          type: Function
      },
    },
    setup(props) {
      const router = useRouter();
      const route = useRoute();
      const navStore = useNavStore();
      const emitter = inject('emitter');
      const { markNotificationAsRead } = useNotificationsApi();
      const userStore = useUserStore();

      const topBarPosition = ref('top-[4.5rem]');

      const bannerRedirect = (path) => {
        if(props.link) {
          //router.push(path)
          window.location.href=path
        }
      }

      const positionBanner = (path) => {
        topBarPosition.value = navStore.publicPages.includes(path) ? 'top-[4.5rem]' : 'top-[0rem]';
      }

      emitter.on('notificationsBanner', (path) => {
        positionBanner(path);

      });

      const closeBanner = (id) => {
        if(id === "sme-survey-banner") {
          props.closeNotification();
        } else {
          markNotificationAsRead(navStore.apiUrl, navStore.authToken, userStore.userObj.token, id).then((response) => {
            console.log('response: ', response);
            console.log('notificationId: ', id);
            props.closeNotification(id);
          })
        }
      }

      onMounted(() => {
        //const pageOrSheet = text.includes("world");
        positionBanner(route.path);
      })

      return {
        topBarPosition,
        navStore,
        closeBanner,
        bannerRedirect
      };
    },
  });
</script>

<style scoped>

</style>