<template>
    <ion-accordion-group :value="expandNestedItems()">
        <ion-accordion v-show="!page.hide" :value="page.title" :disabled="disabled">
            <ion-item slot="header">
            <span class="w-5 mr-4"><DynamicHeroIcon :name="page.iosIcon" /></span>
            <ion-label>{{ page.title }}</ion-label>
            </ion-item>

            <ion-list slot="content" lines="none">
            <ion-menu-toggle 
                v-for="(p, i) in page.nestedPages" 
                :key="i" 
                auto-hide="false"
            >
                <ion-item
                v-show="!p.externalUrl && !p.hide"
                @click="selectedInd = i"
                router-direction="root"
                :router-link="p.url"
                lines="none"
                detail="false"
                class="hydrated cursor-pointer"
                :class="{ selected: p.url === route.path }" 
                >
                <span class="w-5 mx-4"><DynamicHeroIcon :name="p.iosIcon" /></span>
                <ion-label>{{ p.name }} </ion-label>
                <div 
                  v-show="cashflowTwoWeeksEnabled(p.url)"
                  class="flex justify-start items-start gap-2 px-1.5 py-0.5 rounded-lg bg-blue-50 border border-primary-900">
                  <p class="text-[12px] font-semibold text-left text-primary-900">New</p>
                </div>
                </ion-item>
                <a :href="p.url" target="_blank"><ion-item
                v-show="p.externalUrl && !p.hide"
                >
                <span class="w-5 mx-4"><DynamicHeroIcon :name="p.iosIcon" /></span>
                <ion-label>{{ p.name }} </ion-label>
                </ion-item>
                </a>
            </ion-menu-toggle>
            </ion-list>
        </ion-accordion>
    </ion-accordion-group>
</template>

<script>


import { Capacitor } from '@capacitor/core';
import { ref, onMounted } from 'vue'
import {
  IonMenu,
  IonAccordion,
  IonAccordionGroup,
  IonMenuController,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuToggle,
} from "@ionic/vue";
import DynamicHeroIcon from "@/components/icons/DynamicHeroIcon";
import { useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";

export default {
    components: {
        IonItem,
        IonLabel,
        IonList,
        IonMenuToggle,
        IonAccordion,
        IonAccordionGroup,
        DynamicHeroIcon
    },
    props: {
        page: {
            type: Object
        },
        baseClass: {
            type: String,
            default: () => ""
        },
        customClass: {
            type: String,
            default: () => ""
        },
        id: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
    },
    setup(props) {
        const route = useRoute();
        const isNativeApp = ref(Capacitor.isNativePlatform());
        const selectedInd = ref();
        const userStore = useUserStore();
        
        const capitalizeFirstLetter = (string) => {
          if(!string || string.length === 0) return false;
          return string.charAt(0).toUpperCase() + string.slice(1);
        }

        const cashflowTwoWeeksEnabled = (url) => {
          if((url.includes('/analytics/') || url.includes('/home/debt_history')) && userStore.userObj.subscription?.subscribed_since) {
            let dateEnabled;
            if(userStore.userObj.subscription?.subscribed_since) dateEnabled = new Date(userStore.userObj.subscription?.subscribed_since);
            let dateToday = new Date();
            let TimeDiff = dateToday.getTime() - dateEnabled.getTime();
            let DaysDiff = TimeDiff / (1000 * 3600 * 24);
            if(15 - DaysDiff > 0) return true;
            else return false;
          } else {
            return false;
          }
        }

        const expandNestedItems = () => {
          if(route.path.includes('/home')) return 'Analytics'
          if(route.path.includes('home/')) return 'Analytics'
          if(route.path.includes('finance/')) return 'Finance'
          if(route.path.includes('help_centre/')) return 'Help Centre'
          if(route.path.includes('account/')) return 'Account'
        }

        onMounted(async () => {
          cashflowTwoWeeksEnabled('/home');
        })

        return { selectedInd, isNativeApp, route, userStore, cashflowTwoWeeksEnabled, expandNestedItems, capitalizeFirstLetter };
    },
};
</script>

<style>

ion-item {
  --background: transparent;
  --color: #6D738C;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.md ion-item.selected {
  --background: #ffffff;
}

ion-menu.md ion-item:hover {
  --background: #eaf2fa;
  --color: #206BFA;
}

ion-item.selected {
  --color: #206BFA;
}

ion-accordion-group ion-accordion ion-list {
  padding: 0 !important;;
}
ion-accordion-group ion-accordion ion-item {
  --background: #F9FAFB;
}
ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --color: #206BFA;
}

</style>