<template>
  <ion-list :id="id" class="w-full bg-transparent">
    <div :class="['relative flex flex-row', disabledModal ? 'pointer-events-none' : 'cursor-pointer']" @click="openModal">
        <div v-show="!hasImg" class="pl-2">
            <ProfileInitialsIcon :initials="activeAccount ? activeAccount.initials : accounts ? accounts[0].initials : ''" :fillColor="activeAccount ? activeAccount.color : accounts ? accounts[0].color : ''" />
        </div>
        <div v-show="hasImg" class="h-full flex items-center pl-2 mr-3">
            <BankGenIcon customClass="h-1/2 md:h-full w-full flex flex-col justify-center px-[.45rem] bg-primary-700 rounded-full" />
        </div>
        <div class="flex flex-col justify-center items-start relative gap-1">
            <p class="text-[18px] font-bold text-left text-gray-900 whitespace-nowrap">
                {{ activeAccount ? activeAccount.legal_name  : accounts ? accounts[0].legal_name : '' }}
            </p>
            <p class="text-[12px] text-left text-gray-900 whitespace-nowrap">{{ activeAccount ? activeAccount.company_type : accounts ? accounts[0].company_type : ''}}</p>
        </div>
    </div>
  </ion-list>
</template>

<script>
import { IonList, IonItem, IonSelect, IonSelectOption, modalController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import ProfileIcon from '@/components/icons/ProfileIcon';
import ProfileInitialsIcon from '@/components/icons/ProfileInitialsIcon';
import BankGenIcon from '@/components/icons/BankGenIcon';
import SelectModalAccount from "@/components/ui/modals/SelectModalAccount";
export default defineComponent({
  name: "GridSelect",
  components: { IonList, 
  //IonItem, IonSelect, IonSelectOption, 
  ProfileInitialsIcon, 
  BankGenIcon 
  },
  props: {
    accounts: {
      type: Array,
    },
    activeAccount: {
      type: Object,
    },
    items: {
      type: Array,
      default: () => [
      {label: 'Amazing Pizza Co.'},
      {label: 'Bobs Burgers Inc.'},
      {label: 'Jimmy Pestos S.L.'},
    ]
    },
    label: {
        type: String,
        default: () => "Label"
    },
    icon: {
        type: String,
        default: () => "BellNavIcon"
    },
    disabledModal: {
      type: Boolean,
      default: () => false
    },
    id: {
        type: String,
        default: () => "grid-select"
    },
    hasImg: {
      type: Boolean,
      default: () => false
    },
    isCompany: {
      type: Boolean,
      default: () => false
    },
    isAccount: {
      type: Boolean,
      default: () => false
    },
  },
  setup(props) {
    const selectedVal = ref(props.items[0].label);
    const updateSelectedValue = (selected) => {
        selectedVal.value = selected.detail.value;
    }

    const openModal = async () => {
      const modal = await modalController.create({
        component: SelectModalAccount, 
        id:"SelectModalAccount",
        componentProps: {
          isCompany: props.isCompany,
          isAccount: props.isAccount,
          accounts: props.accounts,
          activeAccountObj: props.activeAccount ? props.activeAccount : props.accounts[0]
        },
      });
      return modal.present();
    };

    return { selectedVal, updateSelectedValue, openModal };
  },
});
</script>

<style>
    .action-sheet-group {
        width: 100%;
        position: absolute;
        top: 30%;
    }
    .action-sheet-group-cancel {
        display: none; 
        position: absolute;
        top: 25%;
    }
    .action-sheet-group-cancel.sc-ion-action-sheet-md {
        padding-top: .5rem !important;
    }
    ion-select::part(icon) {
        display: none !important;
    }
    .action-sheet-group.sc-ion-action-sheet-md {
        border-radius: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .action-sheet-button.sc-ion-action-sheet-md {
        padding: 2rem;
    }
    .action-sheet-group.sc-ion-action-sheet-md:first-child {
      padding-top: 1rem;
    }
    .action-sheet-button-inner::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='100' cy='50' r='40' stroke='black' stroke-width='2' fill='red'/%3E%3Cpolyline points='20,20 40,25 60,40 80,120 120,140 200,180' style='fill:none;stroke:black;stroke-width:3'/%3E%3C/svg%3E ");;
    }
</style>
