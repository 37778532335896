

import { CapacitorHttp } from '@capacitor/core';

export default () => {

    const getDemoSheetData = async (apiUrl, token, userToken, filters) => {
        const filtersStr = Object.keys(filters).map(function (key) {
            return key + '=' + filters[key];
        }).join('&');
        const options = {
            url: apiUrl + '/api/v1/open_baking/accounts?show_transactions=1&' + filtersStr,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return response.data;
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const getDemoSheetDataWithPagination = async (apiUrl, token, userToken, filters, pageNumber, entriesPerPage) => {
        const filtersStr = Object.keys(filters).map(function (key) {
            return key + '=' + filters[key];
        }).join('&');
        const options = {
            url: apiUrl + `/api/v1/open_baking/transactions?per_page=${entriesPerPage}&page=${pageNumber}&${filtersStr}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return response.data;
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const getMerchantTurnoverSheetData = async (apiUrl, token, userToken, companyId, filters, pageNumber, entriesPerPage) => {
        const filtersStr = Object.keys(filters).map(function (key) {
            return key + '=' + filters[key];
        }).join('&');

        const options = {
            url: apiUrl + `/api/v1/analytics_data/merchant_turnovers?company_id=${companyId}&${filtersStr}&per_page=${entriesPerPage}&page=${pageNumber}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };

        try {
            const response = await CapacitorHttp.get(options);
            if (response.status === 200) {
                return response.data;
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const getMerchantTurnoverTableData = async (apiUrl, token, userToken, companyId, filters, pageNumber, entriesPerPage) => {
        const filtersStr = Object.keys(filters).map(function (key) {
            return key + '=' + filters[key];
        }).join('&');

        const options = {
            url: apiUrl + `/api/v1/analytics_data/external_fire_transactions?company_id=${companyId}&${filtersStr}&per_page=${entriesPerPage}&page=${pageNumber}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);
            const headers = Object.keys(response.data.transactions[0]).map((header) => {
                return { headerName: capitalizeFirstLetter(header), field: header };
            });
            if (response.status === 200) {
                return { headers: headers, data: response.data };
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const getLastUpdatedDate = async (apiUrl, token, userToken, accountId) => {
        const options = {
            url: apiUrl + `/api/v1/analytics_data/last_updated?account_id=${accountId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                method: 'GET',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        };
        try {
            const response = await CapacitorHttp.get(options);

            if (response.status === 200) {
                return response.data.sheets
            }
        } catch (err) {
            return [err, response.status];
        }
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return {
        getLastUpdatedDate,
        getDemoSheetData,
        getDemoSheetDataWithPagination,
        getMerchantTurnoverSheetData,
        getMerchantTurnoverTableData
    }
}