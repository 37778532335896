
import { CapacitorHttp } from '@capacitor/core';

export default () => {

    const submitNpsScore = async (apiUrl, token, userToken, data) => {
        let headers = {};
        console.log('userToken: ', userToken)
        if (userToken) {
            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            }
        } else {
            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }
        const options = {
            url: `${apiUrl}/api/v1/feedbacks`,
            method: 'POST',
            headers: headers,
            data: JSON.stringify({
                rating: data.rating,
                feedback_type: data.type,
                body: data.body,
                url_completed: data.url_completed,
                question: data.question
            })
        };


        try {
            const response = await CapacitorHttp.post(options);
            if (response.status === 200) {

                return {
                    success: true
                };
            } else {
                return {
                    success: false,
                    error: response.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    const hideTooltip = async (apiUrl, token, userToken, companyId) => {
        const options = {
            url: `${apiUrl}/api/v1/company/products_config/update_show_cashflow_tooltip?company_id=${companyId}`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`,
                Token: userToken,
            },
            data: {
                show_cashflow_tooltip: false
            }
        };


        try {
            const response = await CapacitorHttp.patch(options);
            if (response.status === 200) {
                return {
                    success: true
                };
            } else {
                return {
                    success: false,
                    error: response.data.error
                }
            }
        } catch (err) {
            return err;
        }
    };

    return {
        submitNpsScore,
        hideTooltip
    }
}
