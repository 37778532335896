<template>
    <div class="m-1 mr-2 w-10 h-10 relative flex justify-center items-center rounded-full text-xl">
        <img class="rounded-full" :src="imgUrl" />
    </div>

</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        width: {
            type: String,
            default: () => "18"
        },
        height: {
            type: String,
            default: () => "20"
        },
        fillColor: {
            type: String,
            default: () => "#000000"
        },
        iconClass: {
            type: String,
            default: () => "flex-grow-0 flex-shrink-0 w-5 h-5 relative"
        },
        imgUrl: {
            type: String,
            default: () => "https://play-lh.googleusercontent.com/iraUBoPgVS_e-fIJHV9dsJK4GR8HX-7_nkSKbGDbP4VXrBBI8VTiAWa70qgxH7GLcnk"
        },
    }

});
</script>