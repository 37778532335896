<template>
    <div class="py-5 pr-2 bg-transparent w-10 md:flex flex-col justify-center">
        <div @click="toggleSideMenu" class="bg-white rounded-r-sm p-1 border border-y-4 border-r-4 cursor-pointer">
            <ChevronLeftIcon v-show="isOpen" class="w-5 stroke-[3px] text-neutral-900"/>
            <ChevronRightIcon v-show="!isOpen" class="w-5 stroke-[3px] text-neutral-900"/>
        </div>
    </div>
</template>

<script>

import {
        ChevronLeftIcon,
        ChevronRightIcon
} from '@heroicons/vue/outline';
import { Capacitor } from '@capacitor/core';
import {
  menuController
} from "@ionic/vue";
import { ref, inject } from 'vue';
import { useRouter } from "vue-router";
import { useNavStore } from "@/stores/nav";

export default {
    name: "SideNavArrow",
    components: {
        ChevronLeftIcon,
        ChevronRightIcon
    },
    props: {
        isOpen: {
            type: Boolean,
            default: () => true
        },
    },
    setup() {
        const navStore = useNavStore();
        const emitter = inject('emitter');
        const toggleBool = ref(navStore.showSideNav);

        const toggleSideMenu = async () => {
            toggleBool.value = !toggleBool.value;
            //emitter.emit('toggleSideMenuEventFromArrow', toggleBool.value);
        }

        return {
            toggleBool,
            navStore,
            toggleSideMenu
        };
    },
};
</script>

<style>

</style>
