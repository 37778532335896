<template>
    <div 
        class="m-1 mr-2 w-12 min-w-[3rem] h-12 relative flex justify-center items-center rounded-full text-xl text-white uppercase"
        :style="{backgroundColor: fillColor}">
        <p class="text-2xl font-extrabold text-left text-white uppercase">{{ initials }}</p>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        width: {
            type: String,
            default: () => "18"
        },
        height: {
            type: String,
            default: () => "20"
        },
        fillColor: {
            type: String,
            default: () => "#000000"
        },
        iconClass: {
            type: String,
            default: () => "flex-grow-0 flex-shrink-0 w-5 h-5 relative"
        },
        initials: {
            type: String,
            default: () => "ap"
        },
    }

});
</script>