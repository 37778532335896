import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* vueuse Head for metatags */
import { createHead } from '@vueuse/head'
const head = createHead();

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import VueCookies from 'vue-cookies';

/* Pinia for state management */
import { createPinia } from 'pinia';

/* Event Bus alternative */
import mitt from 'mitt';
const emitter = mitt();

/* Rollbar for error management */
import Rollbar from 'rollbar';

/* Chartjs for data visualisation */
import { Chart, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'

/* Handle Notification */
import Notifications from '@kyvg/vue3-notification'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Tailwind CSS Framework */
import './theme/tailwind.css';

/* Video Player CSS */
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

/* Video language config */
const myLang = {
  dashboard: {
    btn: {
      play: 'Play',
      pause: 'Pause',
      fullscreen: 'Fullscreen',
      exitFullscreen: 'Exit Fullscreen',
      mute: 'Mute',
      unmute: 'Unmute',
      back: 'Back',
      pip: 'Picture in picture',
    },
    settings: {
      autoplay: 'Autoplay',
      loop: 'Loop',
      speed: 'Speed',
      resolution: 'Resolution',
    },
  },
  layers: {
    error: {
      title: '',
      2404: '',
      2502: '',
      2503: '',
      2504: '',
      601: '',
    },
    loading: {
      msg: 'Loading ...',
    },
  },
};

Chart.register(...registerables, zoomPlugin, ChartJsPluginDataLabels);
const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(head)
  .use(VueCookies, { domain: process.env.VUE_APP_DOMAIN })
  .use(Vue3VideoPlayer, {
    lang: myLang
  })
  .use(createPinia())
  .use(Notifications);

const rollbarErrorDetection = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    code_version: '1.0.0'
  }
});

app
  .provide('emitter', emitter)
  .provide('$rollbar', rollbarErrorDetection);

app.config.errorHandler = (err) => {
  rollbarErrorDetection.error(err);
  throw err;

};

// Custom warning handler (only runs in development)
app.config.warnHandler = (warn, vm, info) => {
  console.warn(`[CUSTOM WARNING] ${warn}`);
};

router.isReady().then(() => {
  app.mount('#app');
});