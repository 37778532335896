<template>
  <grid-notifications :key="route.path"></grid-notifications>
  <ion-app>
    <div v-show="!route.matched[0].props.default.isPublic && isMenuOpen" :key="route.path" @click="closeSideMenu"
      :class="['hidden md:block z-[1] absolute left-[304px]']">
      <SideNavArrow :isOpen="isMenuOpen" />
    </div>
    <div v-show="!route.matched[0].props.default.isPublic && !isMenuOpen" :key="route.path" @click="openSideMenu"
      :class="['hidden md:block z-[1] absolute left-0']">
      <SideNavArrow :isOpen="isMenuOpen" />
    </div>
    <GridTooltip
      v-show="showTooltip && userStore?.userObj?.subscription?.plan_name === 'Premium' && (route.path === '/home')"
      :onAction="closeTooltip" />
    <ion-split-pane :key="route.path" :when="'md'" content-id="main-content">
      <side-bar-nav menuId="main-nav" contentId="main-content"
        :class="[route.matched[0].props.default.isPublic && 'md:hidden']">
      </side-bar-nav>
      <side-bar-filter menuId="filter-bar" contentId="main-content"></side-bar-filter>
      <ion-router-outlet id="main-content">
      </ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>
<script>
import { Capacitor } from '@capacitor/core';
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  isPlatform,
  menuController,
  modalController
} from "@ionic/vue";
import { defineComponent, onMounted, ref, inject, watch } from "vue";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { AdvertisingId } from '@capacitor-community/advertising-id';
import SideBarNav from "@/components/navigation/SideBarNav";
import SideBarFilter from "@/components/charts/SideBarFilter";
import { useRoute, useRouter } from "vue-router";
import { useNavStore } from "@/stores/nav";
import GridNotifications from "@/components/notifications/GridNotifications";
import { useUserStore } from "@/stores/user";
import { useHead } from "@vueuse/head"
import useAuthApi from "@/apis/useAuthApi.js";
import useFeedbackApi from "@/apis/useFeedbackApi.js";
import SideNavArrow from "@/components/navigation/SideNavArrow";
import GridTooltip from "@/components/ui/GridTooltip";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    SideBarNav,
    SideBarFilter,
    GridNotifications,
    SideNavArrow,
    GridTooltip
  },
  setup() {
    const emitter = inject('emitter');
    const userStore = useUserStore();
    const sideBarKey = ref(0);
    const metaTag = ref([]);
    const { getCurrentUser } = useAuthApi();
    const { hideTooltip } = useFeedbackApi();
    const nativeIosMobileDevice = isPlatform('ipad') || isPlatform('iphone');
    const isNativeApp = ref(Capacitor.isNativePlatform());
    const isPublic = ref(true);
    const route = useRoute();
    const router = useRouter();
    const sideMenuOpen = ref(true);
    const showSurveyBannerAnalytics = ref(false);
    const sideNavKeyAssist = ref(0);
    const maxWidthMenuClosed = ref(window.innerWidth);
    const marginLeft = ref('!max-w-[85%] !ml-[15%]');
    const maxWidthMenuOpen = ref(window.innerWidth - 304);
    const isMenuOpen = ref(true);
    const showTooltip = ref(true);


    const closeNotification = () => {
      showSurveyBannerAnalytics.value = false;
    }

    const toggleSideMenu = async () => {
      console.log('toggleSideMenu');
      // Open the menu by menu-id
      let isMenuOpen = await menuController.isOpen('main-side-menu');
      if (!isMenuOpen) {
        await menuController.close('main-side-menu')
        await menuController.enable(false, 'main-side-menu');
      } else {
        await menuController.enable(true, 'main-side-menu')
        await menuController.open('main-side-menu');
      }
    };

    const openSideMenu = async () => {
      console.log('openSideMenu');
      isMenuOpen.value = true;
      await menuController.enable(true, 'main-side-menu');
      emitter.emit('openSideMenuEvt');
    };

    const closeSideMenu = async () => {
      console.log('closeSideMenu');
      isMenuOpen.value = false;
      await menuController.enable(false, 'main-side-menu');
      emitter.emit('closeSideMenuEvt');
    };

    const closeTooltip = async () => {
      showTooltip.value = false;
      const result = await hideTooltip(navStore.apiUrl, navStore.authToken, userStore.userObj.token, userStore.userObj.companies && userStore.userObj.activeCompany ? userStore.userObj.activeCompany.id : userStore.userObj.companies ? userStore.userObj.companies[0].id : '');
      if (result.success) console.log('tooltip updated');
    };

    const customToggleSideMenu = async () => {
      console.log('customToggleSideMenu');
      // Open the menu by menu-id
      let isMenuOpen = await menuController.isOpen('main-side-menu');
      console.log('isMenuOpen 2: ', isMenuOpen);
      await menuController.enable(true, 'main-side-menu')
      await menuController.open('main-side-menu');

    };

    const updateAppPages = async (hideBool) => {
      return [
        {
          title: "Dashboard",
          name: "MainDashboard",
          url: "/dashboard",
          iosIcon: 'TemplateIcon',
          meta: { showSideMenu: true },
          mdIcon: 'TemplateIcon',
        },
        {
          title: "My Applications",
          name: "My Applications",
          meta: { showSideMenu: false },
          iosIcon: 'DocumentTextIcon',
          mdIcon: 'DocumentTextIcon',
          nestedPages: [
            {
              title: "Dashboard Alt",
              name: "Dashboard Alt",
              url: "/dashboard_alt",
              meta: { showSideMenu: true },
              iosIcon: 'TemplateIcon',
              mdIcon: 'TemplateIcon',
            },
            {
              title: "Articles",
              name: "Articles",
              url: "/articles",
              meta: { showSideMenu: false },
              iosIcon: 'OfficeBuildingIcon',
              mdIcon: 'OfficeBuildingIcon',
            },
            {
              title: "Testimonials",
              name: "Testimonials",
              url: "/testimonials",
              meta: { showSideMenu: false },
              iosIcon: 'DocumentTextIcon',
              mdIcon: 'DocumentTextIcon',
            }
          ]
        },
        {
          title: "Loans",
          name: "Loans",
          url: "/loans",
          meta: { showSideMenu: false },
          iosIcon: 'DatabaseIcon',
          mdIcon: 'DatabaseIcon',
        },
        {
          title: "Repayments",
          name: "Repayments",
          url: "/repayments",
          iosIcon: 'CreditCardIcon',
          meta: { showSideMenu: false },
          mdIcon: 'CreditCardIcon',
        },
        {
          title: "Reporting",
          name: "Reporting",
          url: "/reporting",
          meta: { showSideMenu: false },
          iosIcon: 'BookmarkIcon',
          mdIcon: 'BookmarkIcon',
        },
        {
          title: "Help Centre",
          name: "Help Centre",
          url: "/help_centre",
          meta: { showSideMenu: false },
          iosIcon: 'ChatAlt2Icon',
          mdIcon: 'ChatAlt2Icon',
        },
        {
          title: "My Profile",
          name: "My Profile",
          url: "/profile",
          iosIcon: 'UserCircleIcon',
          meta: { showSideMenu: false },
          mdIcon: 'UserCircleIcon',
        },
        {
          title: "My Company",
          name: "My Company",
          url: "/showcase",
          meta: { showSideMenu: false },
          iosIcon: 'OfficeBuildingIcon',
          mdIcon: 'OfficeBuildingIcon',
        },
        {
          title: "Login",
          name: "Login",
          url: "/login",
          meta: { showSideMenu: false },
          iosIcon: 'LogoutIcon',
          mdIcon: 'LogoutIcon',
        },
        {
          title: "Logout",
          name: "Logout",
          url: "/logout",
          meta: { showSideMenu: false },
          iosIcon: 'LogoutIcon',
          mdIcon: 'LogoutIcon',
        },
        {
          title: "Register Confirmation",
          name: "Register Confirmation",
          url: "/register_confirmation",
          iosIcon: 'LogoutIcon',
          mdIcon: 'LogoutIcon',
          meta: { showSideMenu: false },
        },
        {
          title: "An Post",
          name: "An Post",
          url: "/an_post",
          iosIcon: 'CreditCardIcon',
          mdIcon: 'CreditCardIcon',
          meta: { showSideMenu: false },
        },
      ]
    }

    const updateTopBarPages = async () => {
      return [
        {
          title: "Homepage",
          name: "Homepage",
          url: "/",
          iosIcon: 'HomeIcon',
          meta: { showSideMenu: false },
          mdIcon: 'HomeIcon',
          hide: true
        },
        {
          title: "Business Finance",
          name: "Business Finance",
          url: "/finance",
          iosIcon: 'CreditCardIcon',
          meta: { showSideMenu: false },
          mdIcon: 'CreditCardIcon',
          hide: false
        },
        {
          title: "Short Term Business Loans",
          name: "Short Term Business Loans",
          url: "/short_term_loans",
          iosIcon: 'CreditCardIcon',
          meta: { showSideMenu: false },
          mdIcon: 'CreditCardIcon',
          hide: false
        },
        {
          title: "Insights",
          name: "Insights",
          url: "/insights",
          meta: { showSideMenu: false },
          iosIcon: 'ChartPieIcon',
          mdIcon: 'ChartPieIcon',
          hide: false
        },
        {
          title: "Partners",
          name: "Partners",
          url: "/partners",
          meta: { showSideMenu: false },
          iosIcon: 'UsersIcon',
          mdIcon: 'UsersIcon',
          hide: false
        },
        {
          title: "Contact Us",
          name: "Contact Us",
          url: "/contact_us",
          meta: { showSideMenu: false },
          iosIcon: 'PhoneIcon',
          mdIcon: 'PhoneIcon',
          hide: false
        },
        {
          title: "Team",
          name: "Team",
          url: "/about/team",
          meta: { showSideMenu: false },
          iosIcon: 'UserGroupIcon',
          mdIcon: ' UserGroupIcon',
          hide: false
        },
        {
          title: "About Us",
          name: "About Us",
          url: "/about_us",
          meta: { showSideMenu: false },
          iosIcon: 'UserGroupIcon',
          mdIcon: ' UserGroupIcon',
          hide: false
        },
        {
          title: "Foundation",
          name: "Foundation",
          url: "/about/foundation",
          meta: { showSideMenu: false },
          iosIcon: 'GlobeIcon',
          mdIcon: 'GlobeIcon',
          hide: false
        },
      ]
    }


    const updateSheets = async (hideBool) => {
      console.log('userStore.userObj.email: ', userStore.userObj.email);
      let analyticsBeta = await userStore.evalWhitelistAnalyticsUsers(userStore.userObj.email);
      return [
        /*{
          title: "Home",
          name: "Home",
          url: "/",
          iosIcon: 'HomeIcon',
          meta: { showSideMenu: false },
          mdIcon: 'HomeIcon',
        },*/
        {
          title: "Analytics",
          name: "Analytics",
          meta: { showSideMenu: true },
          iosIcon: 'ChartPieIcon',
          mdIcon: 'ChartPieIcon',
          hide: !analyticsBeta, //hideBool
          nestedPages: [
            {
              title: "Home",
              name: "Home",
              url: "/home",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Calendar",
              name: "Calendar",
              url: "/home/calendar",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            /*{
              title: "GRID Score",
              name: "GRID Score",
              url: "/analytics/grid_score",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },*/
            /*{
              title: "Credit Bureau Report",
              name: "Credit Bureau Report",
              url: "/analytics/credit_bureau_report",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
              hide: !userStore.userObj.hasCcr
            },*/
            {
              title: "Banking Analysis",
              name: "Your Bank Account",
              url: "/analytics/banking_analysis",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
              hide: (userStore.userObj && !userStore.userObj.hasCashflow),
            },
            /*{
              title: "Company Health",
              name: "Company Health",
              url: "/analytics/company_health",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
              hide: !userStore.userObj.hasCompanyHealth
            },*/
            {
              title: "Transaction Analysis",
              name: "Account Transactions",
              url: "/analytics/transaction_analysis",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
              hide: (userStore.userObj && !userStore.userObj.hasCashflow),
            },
            {
              title: "Categorised Transaction Analysis",
              name: "Account Trends",
              url: "/analytics/categorised_transaction_analysis",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
              hide: (userStore.userObj && !userStore.userObj.hasCashflow),
            },
            {
              title: "Debt History",
              name: "Debt History",
              url: "/home/debt_history",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
              hide: (userStore.userObj && !userStore.userObj.hasCcr),
            },
            /*{
              title: "Sector Comparison",
              name: "Similar Businesses",
              url: "/analytics/sector_comparison",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Macroeconomic Hub",
              name: "Economic Trends",
              url: "/analytics/macroeconomic_hub",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },*/
          ]
        },
        /*{
          title: "Pricing",
          name: "Pricing",
          url: nativeIosMobileDevice ? "/account/billing/ios" : "/account/billing",
          iosIcon: CashIcon,
          meta: { showSideMenu: true },
          mdIcon: CashIcon,
        },*/
        {
          title: "Finance",
          name: "Finance",
          meta: { showSideMenu: true },
          iosIcon: 'CreditCardIcon',
          mdIcon: 'CreditCardIcon',
          hide: (userStore.userObj && !userStore.userObj.hasFinance),
          nestedPages: [
            {
              title: "Finance Dashboard",
              name: "Finance Dashboard",
              url: "/finance/dashboard",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Loan Applications",
              name: "Loan Applications",
              url: "/finance/application",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Your Loans",
              name: "Your Loans",
              url: "/finance/loans",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Loan Repayments",
              name: "Loan Repayments",
              url: "/finance/repayments",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            // {
            //   title: "Your Leases",
            //   name: "Your Leases",
            //   url: "/finance/leases",
            //   iosIcon: '',
            //   meta: { showSideMenu: true },
            //   mdIcon: '',
            // },
            // {
            //   title: "Lease Repayments",
            //   name: "Lease Repayments",
            //   url: "/finance/lease-repayments",
            //   iosIcon: '',
            //   meta: { showSideMenu: true },
            //   mdIcon: '',
            // },
            {
              title: "Term Loan Repayments",
              name: "Term Loan Repayments",
              url: "/finance/term_repayments",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Merchant Transactions",
              name: "Merchant Transactions",
              url: "/finance/merchant_transactions",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            /*{
              title: "Merchant Turnover",
              name: "Your Card Income",
              url: "/finance/turnover",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },*/
            {
              title: "Terminal Income",
              name: "Terminal Income",
              url: "/finance/terminal_income",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Merchant Changeover",
              name: "Merchant Changeover",
              url: "/finance/merchant_changeover",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
          ]
        },
        {
          title: "Notifications",
          name: "Notifications",
          url: "/notifications",
          iosIcon: 'BellIcon',
          meta: { showSideMenu: true },
          mdIcon: 'BellIcon',
          hide: hideBool,
        },
        {
          title: "Downloads",
          name: "Downloads",
          url: "/downloads",
          iosIcon: 'DownloadIcon',
          meta: { showSideMenu: true },
          mdIcon: 'DownloadIcon',
          hide: hideBool,
        },
        {
          title: "Help Centre",
          name: "Help Centre",
          meta: { showSideMenu: false },
          iosIcon: 'InformationCircleIcon',
          mdIcon: 'InformationCircleIcon',
          hide: hideBool,
          nestedPages: [
            /*{
              title: "Chat to someone",
              name: "Chat to someone",
              url: "/analytics/dashboard",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Contact us",
              name: "Contact us",
              url: "/analytics/demo_dashboard_2",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "FAQ's",
              name: "FAQ's",
              url: "/analytics/dashboard",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },*/
            {
              title: "Your Dashboard",
              name: "Your Dashboard",
              url: "/help_centre/guide",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Support",
              name: "Support",
              url: "/help_centre/support",
              hide: false,
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Privacy Policy",
              name: "Privacy Policy",
              url: "/help_centre/privacy_policy",
              hide: false,
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Terms & Conditions",
              name: "Terms & Conditions",
              url: "/help_centre/terms_conditions",
              hide: false,
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Complaints Policy",
              name: "Complaints Policy",
              url: "/help_centre/complaints_policy",
              hide: false,
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Resources",
              name: "Resources",
              url: "https://app.gridfinance.ie/shared/support/resources",
              externalUrl: true,
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "FAQ's",
              name: "FAQ's",
              url: "/help_centre/faqs",
              iosIcon: '',
              meta: { showSideMenu: false },
              mdIcon: '',
            },
          ]
        },
        {
          title: "Settings",
          name: "Settings",
          meta: { showSideMenu: true },
          iosIcon: 'CogIcon',
          mdIcon: 'CogIcon',
          hide: hideBool,
          nestedPages: [
            {
              title: "My Company",
              name: "My Company",
              url: "/account/company_details",
              iosIcon: '',
              hide: true,
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "User Profile",
              name: "User Profile",
              url: "/account/user_profile",
              iosIcon: '',
              hide: true,
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "Identification",
              name: "Identification",
              url: "/account/identification",
              iosIcon: '',
              meta: { showSideMenu: false },
              mdIcon: '',
            },
            {
              title: "Signatory Identification",
              name: "Signatory Identification",
              url: "/finance/application/signatories",
              iosIcon: '',
              meta: { showSideMenu: false },
              mdIcon: '',
            },
            {
              title: "Billing",
              name: "Billing",
              hide: true,
              url: nativeIosMobileDevice ? "/account/billing/ios" : "/account/billing",
              iosIcon: '',
              meta: { showSideMenu: false },
              mdIcon: '',
            },
            {
              title: "Open Banking",
              name: "Open Banking",
              url: "/account/manage_open_banking",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
          ]
        },
        /*{
          title: "Profile",
          name: "Notifications",
          url: "/home/profile",
          iosIcon: 'UserCircleIcon',
          meta: { showSideMenu: true },
          mdIcon: 'UserCircleIcon',
          hide: hideBool,
        },*/
        /*{
          title: "Settings",
          name: "Settings",
          meta: { showSideMenu: true },
          iosIcon: CogIcon,
          mdIcon: CogIcon,
          nestedPages: [
            {
              title: "Company Profile",
              name: "Company Profile",
              url: "/analytics/dashboard",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
            {
              title: "User Profile",
              name: "User Profile",
              url: "/analytics/demo_dashboard_2",
              iosIcon: '',
              meta: { showSideMenu: true },
              mdIcon: '',
            },
          ]
        },*/
      ]
    }

    const updateMetaData = async () => {
      console.log('route.path: ', route.path);
      metaTag.value = [...topBarPages, ...sheets].filter((page, i) => {
        if (route.path === page.url) {
          return page.title
        }
      });
    }

    FirebaseAnalytics.initializeFirebase({
      apiKey: process.env.VUE_APP_FB_API_KEY,
      authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FB_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FB_APP_ID
    });

    const labels = ["Filter 1", "Filter 2", "Filter 3", "Filter 4"];

    const navStore = useNavStore();

    const resetSideBar = async () => {
      sideNavKeyAssist.value += 1;
    }

    watch(() => route.fullPath,
      async () => {
        sideNavKeyAssist.value += 1;
      }
    );

    const adIdPrompt = async () => {
      await AdvertisingId.requestTracking();
      const id = await AdvertisingId.getAdvertisingId();
      console.log('Ad status: ', id && id.status);
    }

    const sideMenuIsOpen = async () => {
      await menuController.isOpen('main-side-menu');
    }

    const checkSideNavKey = async () => {
      let isOpen = await menuController.isOpen('main-side-menu');
      if (isOpen) isMenuOpen.value = false;
      else isMenuOpen.value = true;
    }

    const updateSideNavKey = async () => {
      /*await menuController.enable(true, 'main-side-menu');
      let isOpen = await menuController.isOpen('main-side-menu');
      if (isOpen) {
         try {
            isMenuOpen.value = false;
            await menuController.enable(false, 'main-side-menu');
            await menuController.close('main-side-menu');
            isOpen = await menuController.isOpen('main-side-menu');
            marginLeft.value = '!max-w-[85%] !ml-[15%]';
            console.log('isOpen shoule be false: ', isOpen);
            if(isOpen) sideNavKeyAssist.value += 1;
          } catch {
            console.log('Catch errror');
          }
        } else {
          try {
            isMenuOpen.value = true;
            await menuController.enable(true, 'main-side-menu');
            await menuController.open('main-side-menu');
            isOpen = await menuController.isOpen('main-side-menu');
            marginLeft.value = '!max-w-full !ml-0';
            console.log('isOpen shoule be true: ', isOpen);
            if(!isOpen) sideNavKeyAssist.value += 1;
          } catch {
          console.log('Catch errror');
          }
      }*/
    }

    onMounted(async () => {
      if (userStore.userObj && userStore.userObj.id) showTooltip.value = userStore.userObj.showTooltip;
      isPublic.value = await navStore.evalSideNavPublic(route.path);
      let topBarPages = await updateTopBarPages(isNativeApp.value ? true : !isPublic.value);
      await navStore.getTopBarPages(topBarPages);
      await navStore.setUtmTags();
      if (isNativeApp.value) FirebaseAnalytics.enable();
      if (isNativeApp.value) await adIdPrompt();
      await resetSideBar();
      await checkSideNavKey();
      const path = await navStore.getRedirectUrlParam(window.location.search);
      if (path && path !== 'error') router.push("/login");
    })

    return {
      maxWidthMenuClosed,
      marginLeft,
      maxWidthMenuOpen,
      route,
      navStore,
      labels,
      sideBarKey,
      sideMenuOpen,
      isNativeApp,
      isPublic,
      showSurveyBannerAnalytics,
      sideNavKeyAssist,
      menuController,
      isMenuOpen,
      showTooltip,
      userStore,
      closeTooltip,
      openSideMenu,
      closeSideMenu,
      updateSideNavKey,
      sideMenuIsOpen,
      closeNotification,
      toggleSideMenu,
      customToggleSideMenu
    };
  },
});
</script>

<style>
#cookiescript_injected {
  display: var('none');
  ;
}

#main-content.menu-content {
  max-width: 100%;
  margin-left: 0;
}


#main-content.menu-content-open {
  max-width: var(--max-width-menu-open);
  margin-left: 304px;
  pointer-events: auto;
}

@media only screen and (max-width: 768px) {
  #Smallchat {
    display: none;
  }

  #cookiescript_badge {
    /*display: none !important;*/
  }

  #main-content.menu-content-open {
    margin-left: 0;
  }

}

#Smallchat iframe {
  background-color: transparent !important;
}
</style>
